import { AccountInfo } from '@airgap/beacon-sdk';
import { Account, SignatureType, AccountProvider, AccountSource, AccountType } from './account.provider';
import { ReplaySubject, firstValueFrom, withLatestFrom } from 'rxjs';
import { BeaconWallet } from '@taquito/beacon-wallet';
import { NetworkService } from '../network/network.service';

export class BeaconAccount implements Account<AccountInfo> {
    public type: AccountType = 'tezos';
    public source: AccountSource = 'beacon';
    public get signatureType(): SignatureType {
        if (this.address().startsWith('tz1')) {
            return 'ed25519';
        }
        if (this.address().startsWith('tz2')) {
            return 'ethereum';
        }
        return 'ecdsa';
    }
    private internalAccount: AccountInfo;

    constructor(account: AccountInfo) {
        this.internalAccount = account;
    }

    public address(): string {
        return this.internalAccount.address;
    }

    public name(): string | undefined {
        return undefined;
    }

    public internal(): AccountInfo {
        return this.internalAccount;
    }
}

export class BeaconAccountProvider implements AccountProvider<AccountInfo> {
    public type: AccountType = 'tezos';
    public selectedAccount$: ReplaySubject<Account<AccountInfo> | null> = new ReplaySubject(1);

    public wallet$: ReplaySubject<BeaconWallet | null> = new ReplaySubject(1);

    constructor(private readonly network: NetworkService) {
        this.wallet$.next(null);
        this.network.currentNetwork$.pipe(withLatestFrom(this.wallet$)).subscribe(async ([network, currentWallet]) => {
            if (currentWallet !== null) {
                await currentWallet.clearActiveAccount();
                await currentWallet.disconnect();
            }
            const wallet = new BeaconWallet({
                name: 'Acurast Console',
                network: {
                    rpcUrl: network.tezos.nodeUrl,
                    type: network.tezos.networkType,
                },
            });
            this.wallet$.next(wallet);
            wallet.client
                .getActiveAccount()
                .then((account) => this.selectedAccount$.next(account ? new BeaconAccount(account) : null));
        });
    }

    public async connect(): Promise<void> {
        const wallet = await firstValueFrom(this.wallet$);
        const account = await wallet?.client.getActiveAccount();
        this.selectedAccount$.next(account ? new BeaconAccount(account) : null);
    }

    public async disconnect(): Promise<void> {
        const wallet = await firstValueFrom(this.wallet$);
        await wallet?.clearActiveAccount();
        this.selectedAccount$.next(null);
    }

    public async requestAccount(): Promise<Account<AccountInfo> | null> {
        const wallet = await firstValueFrom(this.wallet$);
        await wallet?.requestPermissions();
        const activeAccount = await wallet?.client.getActiveAccount();
        const account = activeAccount ? new BeaconAccount(activeAccount) : null;
        this.selectedAccount$.next(account);
        return account;
    }
}
