<div
  class="overflow-hidden border border-gray-50 sm:rounded-lg sm:shadow w-full h-full overflow-y-auto mb-3 cursor-pointer"
>
  <div class="flex flex-row justify-between px-4 py-5">
    <div class="flex flex-row items-center gap-3">
      <div
        class="flex items-center justify-center bg-gray-900 rounded status--container"
      >
        <fa-icon
          [icon]="['fas', 'graduation-cap']"
          class="text-primary-900"
        ></fa-icon>
      </div>
      <div class="text-white">
        <h3 class="text-base font-semibold leading-6 text-white">
          Introduction
        </h3>
      </div>
    </div>
    <div class="flex items-center">
      <fa-icon
        [icon]="['fas', 'lock']"
        class="text-gray-800"
        *ngIf="statusLocked"
      ></fa-icon>
    </div>
  </div>

  <div class="px-4 py-5" *ngIf="description">
    <!--TODO: carousel for content -->

    <div id="default-carousel" class="relative w-full" data-carousel="slide">
      <!-- Carousel wrapper -->
      <div class="relative overflow-hidden rounded-lg">
        <!-- Item 1 -->
        <div class="duration-700 ease-in-out" data-carousel-item>
          <!--TODO: text content component -->
          <div class="pb-8">
            {{ description }}
          </div>

          <div class="relative mb-8">
            <!--TODO: remove this, only for visual representation-->
            <div class="absolute inset-0 flex items-center" aria-hidden="true">
              <div class="w-full border-t border-gray-300"></div>
            </div>
            <div class="relative flex justify-center">
              <span class="bg-white px-2 text-sm text-gray-500"
                >Quiz Component</span
              >
            </div>
          </div>

          <!--TODO: quiz component-->
          <div>
            <div class="pb-8">
              <h5 class="text-base uppercase leading-6 text-white pb-3">
                Quiz
              </h5>
              <p>Let's put our learnings to the test.</p>
            </div>
            <h3 class="text-base font-semibold leading-6 text-white pb-3">
              What does Acurast's decentralized cloud for compute power?
            </h3>
            <div class="flex flex-col gap-3">
              <button
                type="button"
                class="rounded-md bg-black text-white w-full px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:text-black hover:bg-primary-900"
              >
                Servers
              </button>
              <button
                type="button"
                class="rounded-md bg-black text-white w-full px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:text-black hover:bg-primary-900"
              >
                Bananas
              </button>
              <button
                type="button"
                class="rounded-md bg-black text-white w-full px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:text-black hover:bg-primary-900"
              >
                Phones
              </button>
            </div>
          </div>

          <div class="relative my-8">
            <!--TODO: remove this, only for visual representation-->
            <div class="absolute inset-0 flex items-center" aria-hidden="true">
              <div class="w-full border-t border-gray-300"></div>
            </div>
            <div class="relative flex justify-center">
              <span class="bg-white px-2 text-sm text-gray-500"
                >Action Component</span
              >
            </div>
          </div>

          <!--TODO: action component -->

          <div>
            <div class="pb-8">
              <h5 class="text-base uppercase leading-6 text-white pb-3">
                Follow Acurast on X
              </h5>
              <p>Get the latest updates on Acurast on X.</p>
            </div>
            <h3 class="text-base font-semibold leading-6 text-white pb-3">
              Connect and follow Acurast on X
            </h3>
            <button
              type="button"
              class="rounded-md bg-black text-white w-full px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:text-black hover:bg-primary-900"
            >
              Follow Acurast
              <fa-icon [icon]="['fab', 'x-twitter']" class="ml-1"></fa-icon>
            </button>
          </div>

          <div class="relative my-8">
            <!--TODO: remove this, only for visual representation-->
            <div class="absolute inset-0 flex items-center" aria-hidden="true">
              <div class="w-full border-t border-gray-300"></div>
            </div>
            <div class="relative flex justify-center">
              <span class="bg-white px-2 text-sm text-gray-500"
                >Prerequisite Component</span
              >
            </div>
          </div>

          <!--TODO: preqrequisite component -->
          <div class="pb-8">
            <div class="pb-8">
              <h5 class="text-base uppercase leading-6 text-white pb-3">
                Quest Completion Required
              </h5>
              <p>
                You can only continue if you have completed the previous quest.
              </p>
            </div>
            <div
              class="overflow-hidden border border-gray-50 sm:rounded-lg sm:shadow w-full h-full overflow-y-auto mb-3 cursor-pointer card--container mb-8"
            >
              <div class="flex flex-row items-center gap-8 p-5">
                <div>
                  <img
                    src="/assets/img/quests/1.png"
                    width="96px"
                    height="96px"
                    class="rounded"
                  />
                </div>

                <div>
                  <p class="pb-3">
                    Completed
                    <fa-icon
                      [icon]="['fas', 'check-circle']"
                      class="text-primary-900"
                    ></fa-icon>
                  </p>
                  <h3 class="text-base font-semibold leading-6 text-white">
                    Welcome to the Cloud Rebellion
                  </h3>
                  <p class="text-gray-300">
                    Learn about the Cloud Rebellion and Acurast.
                  </p>
                  <div class="py-3">
                    <div class="flex flex-row justify-between pb-3">
                      <p class="text-sm">3 Steps</p>
                    </div>
                    <!--TODO: progress -->
                    <div class="progress--container w-full">
                      <div
                        class="progress--steps bg-primary-900"
                        [ngStyle]="{ width: '33%' }"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              type="button"
              class="rounded-md bg-black text-white px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:text-black hover:bg-primary-900"
            >
              Open Quest
            </button>
          </div>
        </div>
        <!-- Item 2 -->
        <div class="hidden duration-700 ease-in-out" data-carousel-item></div>
        <!-- Item 3 -->
        <div class="hidden duration-700 ease-in-out" data-carousel-item></div>
      </div>
      <!-- Slider indicators -->
      <div
        class="absolute z-30 flex -translate-x-1/2 bottom-5 left-1/2 space-x-3 rtl:space-x-reverse"
      >
        <button
          type="button"
          class="w-3 h-3 rounded-full bg-white"
          aria-current="true"
          aria-label="Slide 1"
          data-carousel-slide-to="0"
        ></button>
        <button
          type="button"
          class="w-3 h-3 rounded-full bg-gray-500"
          aria-current="false"
          aria-label="Slide 2"
          data-carousel-slide-to="1"
        ></button>
        <button
          type="button"
          class="w-3 h-3 rounded-full bg-gray-500"
          aria-current="false"
          aria-label="Slide 3"
          data-carousel-slide-to="2"
        ></button>
      </div>
    </div>
  </div>
</div>
