import { Component, Input, OnInit } from '@angular/core';
import BigNumber from 'bignumber.js';
import { Account } from 'src/app/services/account/account.provider';

const { createIcon } = require('@download/blockies');

@Component({
  selector: 'acurast-identicon',
  templateUrl: './identicon.component.html',
  styleUrls: ['./identicon.component.scss'],
})
export class IdenticonComponent implements OnInit {
  private static readonly b52Characters =
    '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz';

  @Input()
  public account!: Account<any>;

  public identicon?: string = undefined;

  constructor() {}

  public ngOnInit(): void {
    if (this.account.type === 'tezos') {
      this.identicon = createIcon({
        seed: `0${this.b582int(this.account.address())}`,
        spotcolor: '#000',
      }).toDataURL();
    }
  }

  private b582int(v: string): string {
    let rv = new BigNumber(0);
    for (let i = 0; i < v.length; i++) {
      rv = rv.plus(
        new BigNumber(
          IdenticonComponent.b52Characters.indexOf(v[v.length - 1 - i])
        ).multipliedBy(
          new BigNumber(
            IdenticonComponent.b52Characters.length
          ).exponentiatedBy(i)
        )
      );
    }

    return rv.toString(16);
  }
}
