import { Injectable } from '@angular/core';
import { NotificationService } from '../notification/notification.service';

@Injectable({
  providedIn: 'root',
})
export class ClipboardService {
  constructor(private readonly notification: NotificationService) {}

  public async copy(text: string): Promise<void> {
    return navigator.clipboard.writeText(text).then(() => {
      this.notification.prompt({
        type: 'success',
        title: 'Copied to clipboard',
        message: `${text}`,
      });
    });
  }
}
