<!-- announcement landing
<div class="h-full container--background">
  <div class="h-full hero--background">
    <div class="flex flex-col items-center justify-center py-8 h-full">
      <img
        src="/assets/img/airship.png"
        class="w-96 absolute z-1 airship--container"
      />
      <div class="flex flex-col items-center justify-center relative z-10">
        <img src="/assets/img/Acurast-dark-bg.svg" class="w-96 pb-24" />
        <h1 class="text-white text-6xl 2xl:text-8xl">
          The Cloud Rebellion Is Assembling
        </h1>
        <h3 class="text-white text-center text-2xl pb-4 mx-8 max-w-prose">
          Do your part and rise against the monopoly of the Cloud.
        </h3>
        <h3 class="text-white text-center text-2xl pb-8 mx-8 max-w-prose">
          Join the Acurast Discord and stay up-to-date on the start of the
          Rebellion.
        </h3>
        <button
          routerLink="/dashboard"
          type="button"
          class="rounded-md bg-primary-900 px-6 py-5 text-xl font-semibold text-black shadow-xl hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
        >
          Join Discord
        </button>
      </div>
    </div>
  </div>
</div>
-->

<!-- launch landing -->
<div class="container--background">
  <div class="h-full hero--background">
    <div class="flex flex-col justify-center md:p-16 h-full">
      <img
        src="/assets/img/airship.png"
        class="hidden md:block w-96 absolute z-1 airship--container"
      />
      <div class="container mx-auto">
        <div
          class="grid lg:grid-cols-3 xl:grid-cols-2 items-start justify-center relative z-10"
        >
          <div
            class="flex flex-col lg:col-span-2 xl:col-span-1 items-center hero--container p-8 max-w-112 rounded-md text-white text-center"
          >
            <h2 class="text-white text-4xl 2xl:text-6xl pb-3">
              The <span class="text-primary-900">Cloud Rebellion</span> Is
              Assembling
            </h2>
            <h3 class="max-w-prose pb-8">
              Do your part and rise against the monopoly of the Cloud and become
              part of the largest
              <span class="text-primary-900"
                >decentralized cloud powered by mobile phones</span
              >.
            </h3>
            <p>Early Access Code:</p>
            <div
              class="code--container flex flex-row justify-center gap-2 py-8 md:p-8"
            >
              @for (item of accessCodeParts; track item; let i = $index) {
              <input
                [class.is-invalid]="inputStatus === 'invalid'"
                [class.is-valid]="inputStatus === 'valid'"
                [(ngModel)]="accessCodeParts[i]"
                (keydown)="manageFocus($event, i)"
                (paste)="managePaste($event)"
                maxLength="1"
                #inputElement
                type="text"
                class="block w-full rounded-md border-0 p-2 bg-black text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-900 sm:text-sm sm:leading-6"
              />
              }
            </div>
            <div *ngIf="inputStatus === 'loading'">Loading...</div>
            <div *ngIf="inputStatus === 'invalid'" class="error">
              Unknown access code.
            </div>
            <p class="pb-6">
              Get an early access code.
              <a
                href="https://discord.gg/wqgC6b6aKe"
                target="_blank"
                class="text-primary-900"
                >Join our Discord</a
              >
            </p>
            <button
              [disabled]="inputStatus !== 'valid'"
              routerLink="/dashboard"
              type="button"
              class="block w-full rounded-md bg-primary-900 px-6 py-5 text-md font-semibold text-black shadow-xl hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
            >
              Join The Cloud Rebellion
            </button>
            <div class="relative py-8 w-full">
              <div
                class="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div class="w-full border-t border-gray-300"></div>
              </div>
            </div>
            <p class="pb-6">
              Already part of the Rebellion?
              <button class="text-primary-900" (click)="toggleLoginModalOpen()">
                Login now
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="bg--second--background">
  <div class="container mx-auto text-white px-8 py-24">
    <div class="flex flex-col text-center items-center pb-16">
      <h3 class="text-4xl max-w-6xl px-8 pb-6">
        Become part of the
        <span class="text-primary-900">Cloud Rebellion</span> and ascend the
        ranks by collecting
        <span class="text-primary-900">MIST ☁️</span>
      </h3>
      <p class="max-w-prose text-gray-300">
        MIST ☁️ ("points") are a crucial ressource for the Rebellion against the
        Cloud monopoly, symbolizing the contribution to the cause of the
        rebellion in the fight for decentralization.
      </p>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 pt-16 gap-8">
      <div class="flex flex-col justify-between max-w-lg">
        <div>
          <h5 class="text-3xl pb-3">Onboard Processors</h5>
          <p class="text-gray-300">
            Cloud-harvest MIST ☁️ by onboarding mobile phones as Processors,
            contributing compute power to Acurast's decentralized Cloud
          </p>
        </div>
        <div class="relative w-full py-6">
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="w-full border-t border-primary-500"></div>
          </div>
        </div>
        <div>
          <h5 class="text-3xl pb-3">Deploy applications</h5>
          <p class="text-gray-300">
            Deploy applications to Acurast's decentralized Cloud and
            cloud-harvest MIST ☁️
          </p>
        </div>
        <div class="relative w-full py-6">
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="w-full border-t border-primary-500"></div>
          </div>
        </div>
        <div>
          <h5 class="text-3xl pb-3">Recurit others</h5>
          <p class="text-gray-300">
            Recurit others to join the Rebellion and setup Processors, providing
            compute, to cloud-harvest more MIST ☁️
          </p>
        </div>
        <div class="relative w-full py-6">
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="w-full border-t border-primary-500"></div>
          </div>
        </div>
        <div>
          <h5 class="text-3xl pb-3">Embark on quests and rank-up</h5>
          <p class="text-gray-300">
            Grow the Cloud Rebellion by embarking on quests and ascending
            through the ranks in upcoming Seasons
          </p>
        </div>
      </div>
      <img src="/assets/img/rebellion-features.png" />
    </div>
  </div>
</div>

<app-modal [open]="loginModalOpen" (onClickClose)="toggleLoginModalOpen()">
  <app-login-modal> </app-login-modal>
</app-modal>
