import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-rank-item',
  templateUrl: './rank-item.component.html',
  styleUrls: ['./rank-item.component.scss'],
})
export class RankItemComponent implements OnInit {
  @Input() size: string = 'small';
  @Input() rankName: string = 'Sky Marshal';
  public rank: string = '';

  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(): void {
    // TODO only when rank name changes
    this.rank = this.rankName.replace(/\s+/g, '-').toLowerCase();
  }
}
