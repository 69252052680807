import { Component, Input } from '@angular/core';
import { UserEntry } from 'src/app/services/api/api.service';

@Component({
  selector: '[leaderboard-item]',
  // standalone: true,
  // imports: [],
  templateUrl: './leaderboard-item.component.html',
  styleUrl: './leaderboard-item.component.scss',
})
export class LeaderboardItemComponent {
  @Input() data?: UserEntry;
}
