import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { PolkadotIdentIconModule } from 'polkadot-angular-identicon';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingComponent } from './pages/landing/landing.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { LeaderboardComponent } from './pages/leaderboard/leaderboard.component';
import { ModalComponent } from './components/modal/modal.component';
import { QuestCardComponent } from './components/quest-card/quest-card.component';
import { FooterComponent } from './components/footer/footer.component';
import { RankItemComponent } from './components/rank-item/rank-item.component';
import { QuestTaskItemComponent } from './components/quest-task-item/quest-task-item.component';
import {
  FontAwesomeModule,
  FaIconLibrary,
} from '@fortawesome/angular-fontawesome';
import {
  faCheckCircle,
  faCircleQuestion,
  faGraduationCap,
  faLock,
  faSquare,
} from '@fortawesome/free-solid-svg-icons';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { LoginModalComponent } from './components/login-modal/login-modal.component';
import { FormsModule } from '@angular/forms';
import { AccountSelectionModalComponent } from './components/account/account-selection-modal/account-selection-modal.component';
import { SpinnerComponent } from './components/elements/spinner/spinner.component';
import { ButtonComponent } from './components/elements/button/button.component';
import { AccountSelectionModalHeadingComponent } from './components/account/account-selection-modal-heading/account-selection-modal-heading.component';
import { AccountListComponent } from './components/account/account-list/account-list.component';
import { IdenticonComponent } from './components/elements/identicon/identicon.component';
import { CommonModule } from '@angular/common';
import { HeadingComponent } from './components/elements/heading/heading.component';
import { ShortenPipe } from './pipes/shorten/shorten.pipe';
import { AccountNamePipe } from './pipes/account-name/account-name.pipe';
import { AmountConverterPipe } from './pipes/amount-converter/amount-converter.pipe';
import { AcurastModalComponent } from './components/elements/modal/modal.component';
import { IconComponent } from './components/elements/icon/icon.component';
import { NotificationComponent } from './components/elements/notification/notification.component';
import { LeaderboardItemComponent } from './components/leaderboard-item/leaderboard-item.component';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    DashboardComponent,
    NavbarComponent,
    LeaderboardComponent,
    ModalComponent,
    QuestCardComponent,
    FooterComponent,
    RankItemComponent,
    QuestTaskItemComponent,
    LoginModalComponent,
    SpinnerComponent,
    IdenticonComponent,
    IconComponent,
    ButtonComponent,
    HeadingComponent,
    AcurastModalComponent,
    AccountListComponent,
    AccountSelectionModalComponent,
    AccountSelectionModalHeadingComponent,
    NotificationComponent,
    LeaderboardItemComponent,
    AmountConverterPipe,
    AccountNamePipe,
    ShortenPipe,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FontAwesomeModule,
    FormsModule,
    PolkadotIdentIconModule,
    CommonModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faGraduationCap,
      faSquare,
      faLock,
      faCheckCircle,
      faXTwitter,
      faCircleQuestion
    );
  }
}
