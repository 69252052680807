import { NetworkType } from '@airgap/beacon-sdk';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { environment } from 'src/environments/environment';

const ACURAST_DECIMALS: number = 12;
const TEZOS_DECIMALS = 6;

export type AcurastNetworkType =
  | 'local'
  | 'dev'
  | 'testnet'
  | 'canary'
  | 'mainnet';

export type AcurastNetworkTypeMapping = {
  [Key in AcurastNetworkType]: Key extends 'local'
    ? 'Acurast Local'
    : Key extends 'dev'
    ? 'Acurast Dev'
    : Key extends 'testnet'
    ? 'Acurast Testnet'
    : Key extends 'canary'
    ? 'Acurast Canary'
    : Key extends 'mainnet'
    ? 'Acurast Mainnet'
    : never;
};

export type AcurastNetworkName<T extends AcurastNetworkType> =
  T extends keyof AcurastNetworkTypeMapping
    ? AcurastNetworkTypeMapping[T]
    : never;

export interface Network {
  type: AcurastNetworkType;
  name: AcurastNetworkName<AcurastNetworkType>;
  acurast: AcurastNetwork;
  tezos: TezosNetwork;
  disabled?: boolean;
  jobEnvVarEnabled: boolean;
}

export interface AcurastNetwork {
  wsUrl: string;
  httpUrl: string;
  apkUrl: string;
  changelogUrl: string;
  apkVersion: string;
  apkChecksum: string;
  deviceAdminComponentName: string;
  faucetFrontendUrl?: string;
  symbol: 'dACU' | 'tACU' | 'cACU' | 'ACU';
  decimals: number;
}

export interface TezosNetwork {
  nodeUrl: string;
  networkType: NetworkType;
  proxyContract: string;
  acuToken: TezosTokenInfo;
  uusdToken: TezosTokenInfo;
  exampleConsumerContract: string;
  _3RouteContract?: string;
  blockExplorer: string;
  symbol: 'tez';
  decimals: number;
}

export interface TezosTokenInfo {
  address: string;
  tokenId: number;
}

const LastSelectedNetworkKey = 'acurast-selected-network';

const ACURAST_DEVNET: AcurastNetwork = {
  wsUrl: 'wss://wss.collator-1.acurast.papers.tech',
  httpUrl: 'https://rpc.collator-1.acurast.papers.tech',
  // Link to the release for the changelog, not anymore to the download url of the apk
  apkUrl:
    'https://github.com/Acurast/acurast-processor-update/releases/download/processor-1.5.0/processor-1.5.0-devnet.apk',
  changelogUrl:
    'https://github.com/Acurast/acurast-processor-update/releases/tag/processor-1.5.0',
  apkVersion: '1.5.0',
  apkChecksum: '7HDCpOByoPWGVSpoNXsjaXydRfHhJXqMTSmiWsSYJDM',
  deviceAdminComponentName:
    'com.acurast.attested.executor.devnet/com.acurast.attested.executor.lockdown.LockdownDeviceAdminReceiver',
  symbol: 'dACU',
  decimals: ACURAST_DECIMALS,
};

const ACURAST_TESTNET: AcurastNetwork = {
  wsUrl: 'wss://acurast-testnet-ws.prod.gke.acurast.com',
  httpUrl: 'https://acurast-testnet-node.prod.gke.acurast.com',
  // Link to the release for the changelog, not anymore to the download url of the apk
  apkUrl:
    'https://github.com/Acurast/acurast-processor-update/releases/download/processor-1.5.0/processor-1.5.0-testnet.apk',
  changelogUrl:
    'https://github.com/Acurast/acurast-processor-update/releases/tag/processor-1.5.0',
  apkVersion: '1.5.0',
  apkChecksum: '7HDCpOByoPWGVSpoNXsjaXydRfHhJXqMTSmiWsSYJDM',
  deviceAdminComponentName:
    'com.acurast.attested.executor.testnet/com.acurast.attested.executor.lockdown.LockdownDeviceAdminReceiver',
  symbol: 'tACU',
  decimals: ACURAST_DECIMALS,
};

const ACURAST_CANARY: AcurastNetwork = {
  wsUrl: 'wss://acurast-canarynet-ws.prod.gke.acurast.com',
  httpUrl: 'https://acurast-canarynet-node.prod.gke.acurast.com',
  // Link to the release for the changelog, not anymore to the download url of the apk
  apkUrl:
    'https://github.com/Acurast/acurast-processor-update/releases/download/processor-1.5.0/processor-1.5.0-canary.apk',
  changelogUrl:
    'https://github.com/Acurast/acurast-processor-update/releases/tag/processor-1.5.0',
  apkVersion: '1.5.0',
  apkChecksum: '7HDCpOByoPWGVSpoNXsjaXydRfHhJXqMTSmiWsSYJDM',
  deviceAdminComponentName:
    'com.acurast.attested.executor.canary/com.acurast.attested.executor.lockdown.LockdownDeviceAdminReceiver',
  faucetFrontendUrl: 'https://faucet.acurast.com',
  symbol: 'cACU',
  decimals: ACURAST_DECIMALS,
};

const TEZOS_GHOSTNET: TezosNetwork = {
  nodeUrl: 'https://tezos-ghostnet-node.prod.gke.papers.tech',
  networkType: NetworkType.GHOSTNET,
  proxyContract: 'KT1CfSASB4rGq8F1SaKXA8GWveCyijkTwY92',
  exampleConsumerContract: 'KT1EXHouK9jYyrKKrNnvjKhQZMffPVfE1zep',
  blockExplorer: 'https://ghostnet.tzkt.io',
  acuToken: {
    address: 'KT1SRPn9JDks4KrVLwnBaW1z5THDYZzEzUpZ',
    tokenId: 0,
  },
  uusdToken: {
    address: 'KT1DVcnL3k2KHK4vrZTK7oUjCYd2s6Vr8qoE',
    tokenId: 0,
  },
  symbol: 'tez',
  decimals: TEZOS_DECIMALS,
};

const TEZOS_MAINNET: TezosNetwork = {
  nodeUrl: 'https://tezos-node.prod.gke.papers.tech',
  networkType: NetworkType.MAINNET,
  proxyContract: 'KT1D8kmxQgZiMJjFLp5L1mkYQaysHyat7v7h',
  exampleConsumerContract: 'KT1MZA3XY7BcgLqP4Qd7CfLpdJHwNJBxug2k',
  blockExplorer: 'https://tzkt.io',
  acuToken: {
    address: 'KT1Vk5VhzwXez6zPFd46r9f9ZhYQkefRGbQr',
    tokenId: 0,
  },
  uusdToken: {
    address: 'KT1XRPEPXbZK25r3Htzp2o1x7xdMMmfocKNW',
    tokenId: 0,
  },
  _3RouteContract: 'KT1R7WEtNNim3YgkxPt8wPMczjH3eyhbJMtz',
  symbol: 'tez',
  decimals: TEZOS_DECIMALS,
};

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  public currentNetwork$: ReplaySubject<Network> = new ReplaySubject(1);
  private networkMap: Map<AcurastNetworkType, Network> = new Map([
    [
      'dev',
      {
        type: 'dev',
        name: 'Acurast Dev',
        acurast: ACURAST_DEVNET,
        tezos: TEZOS_GHOSTNET,
        jobEnvVarEnabled: true,
      },
    ],
    [
      'testnet',
      {
        type: 'testnet',
        name: 'Acurast Testnet',
        acurast: ACURAST_TESTNET,
        tezos: TEZOS_GHOSTNET,
        disabled: true,
        jobEnvVarEnabled: true,
      },
    ],
    [
      'canary',
      {
        type: 'canary',
        name: 'Acurast Canary',
        acurast: ACURAST_CANARY,
        tezos: TEZOS_MAINNET,
        jobEnvVarEnabled: true,
      },
    ],
  ]);
  public get networks(): Network[] {
    return Array.from(this.networkMap.values());
  }

  constructor() {
    if (environment.isLocal) {
      this.addLocalNetwork();
    }
    const storedNetworkType = localStorage.getItem(LastSelectedNetworkKey);
    let selectedNetwork: Network | undefined = undefined;
    if (storedNetworkType !== null) {
      selectedNetwork = this.networks.find(
        (item) => item.type === storedNetworkType
      );
    }
    if (selectedNetwork !== undefined) {
      this.currentNetwork$.next(selectedNetwork);
    } else {
      this.currentNetwork$.next(this.networkMap.get('canary')!);
    }
  }

  public changeTo(networkType: AcurastNetworkType) {
    const next = this.networkMap.get(networkType);
    if (next) {
      this.currentNetwork$.next(next);
      localStorage.setItem(LastSelectedNetworkKey, next.type);
    }
  }

  private addLocalNetwork() {
    this.networkMap.set('local', {
      type: 'local',
      name: 'Acurast Local',
      acurast: {
        wsUrl: 'ws://127.0.0.1:8802',
        httpUrl: 'http://127.0.0.1:8083',
        apkUrl:
          'https://github.com/Acurast/acurast-processor-update/releases/download/processor-1.5.0/processor-1.5.0-devnet.apk',
        changelogUrl:
          'https://github.com/Acurast/acurast-processor-update/releases/tag/processor-1.5.0',
        apkVersion: '1.5.0',
        apkChecksum: '7HDCpOByoPWGVSpoNXsjaXydRfHhJXqMTSmiWsSYJDM',
        deviceAdminComponentName:
          'com.acurast.attested.executor.devnet/com.acurast.attested.executor.lockdown.LockdownDeviceAdminReceiver',
        symbol: 'dACU',
        decimals: ACURAST_DECIMALS,
      },
      tezos: TEZOS_GHOSTNET,
      jobEnvVarEnabled: true,
    });
  }
}
