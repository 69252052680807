import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Account } from 'src/app/services/account/account.provider';

@Component({
  selector: 'acurast-account-list',
  templateUrl: './account-list.component.html',
  styleUrls: ['./account-list.component.scss'],
})
export class AccountListComponent implements OnInit {
  @Input() accounts: Account<any>[] = [];
  @Output() onSelectedAccount = new EventEmitter<Account<any>>();

  constructor() {}

  public ngOnInit(): void {}

  public selectAccount(account?: Account<any>): void {
    this.onSelectedAccount.emit(account);
  }
}
