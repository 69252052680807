import { Injectable } from '@angular/core';
import { AcurastNotification } from 'src/app/components/elements/notification/notification.component';
const { v4: uuidv4 } = require('uuid');

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  public notifications: AcurastNotification[] = [];

  constructor() {}

  public prompt(
    notification: Omit<AcurastNotification, 'id'>
  ): AcurastNotification {
    const result: AcurastNotification = {
      id: uuidv4(),
      ...notification,
    };
    this.notifications.push(result);
    setTimeout(() => {
      this.close(result);
    }, 3000);
    return result;
  }

  public close(notification: Pick<AcurastNotification, 'id'>) {
    const index = this.notifications.findIndex(
      (value) => value.id === notification.id
    );
    if (index >= 0) {
      this.notifications.splice(index, 1);
    }
  }
}
