<p class="pt-5 text-gray-600">Select Account</p>

<div class="flex justify-center">
  <ul class="w-full divide-y divide-gray-200">
    <li
      *ngFor="let account of accounts"
      class="w-full border-b-2 border-neutral-100 border-opacity-100 py-4 dark:border-opacity-50 cursor-pointer"
      (click)="[selectAccount(account)]"
    >
      <div class="flex gap-3 items-center">
        <acurast-identicon [account]="account"></acurast-identicon>
        <div>
          <p class="leading-tight">
            {{ account | accountName : { onlyName: true } }}
          </p>
          <p class="text-xs text-neutral-500 dark:text-neutral-400">
            {{ account | accountName : { onlyAddress: true } }}
          </p>
        </div>
      </div>
    </li>
  </ul>
</div>
