import {
  Observable,
  ReplaySubject,
  combineLatest,
  firstValueFrom,
  map,
} from 'rxjs';
import {
  Account,
  SignatureType,
  AccountProvider,
  AccountSource,
  AccountType,
  GetAccount,
} from './account.provider';
import { InjectedAccountWithMeta } from '@polkadot/extension-inject/types';
import {
  PolkadotExtensionAccount,
  PolkadotExtensionAccountProvider,
} from './polkadot-extension.account.provider';
import {
  SuperHeroAccount,
  SuperheroAccountProvider,
  SuperheroWalletAddressResponse,
} from './superhero.account.provider';
import { NetworkService } from '../network/network.service';
import { TabSyncService } from '../tab-sync/tab-sync.service';
import {
  PasskeyAccount,
  PasskeyAccountData,
  PasskeyAccountProvider,
} from './passkey.account.provider';
import { ApiPromise } from '@polkadot/api';
import { Signer } from '@polkadot/api/types';

type SubstrateProxyAccountOptions =
  | PolkadotExtensionAccount
  | SuperHeroAccount
  | PasskeyAccount;
type SubstrateProxyAccountInteralsOptions =
  | InjectedAccountWithMeta
  | SuperheroWalletAddressResponse
  | PasskeyAccountData;

export class SubstrateProxyAccount
  implements Account<SubstrateProxyAccountInteralsOptions>
{
  public type: AccountType = 'substrate';

  public get source(): AccountSource {
    return this.internalAccount.source;
  }

  public get signatureType(): SignatureType {
    return this.internalAccount.signatureType;
  }

  private internalAccount: SubstrateProxyAccountOptions;

  constructor(account: SubstrateProxyAccountOptions) {
    this.internalAccount = account;
  }

  public address(): string {
    return this.internalAccount.address();
  }

  public name(): string | undefined {
    return this.internalAccount.name();
  }

  public internal(): SubstrateProxyAccountInteralsOptions {
    return this.internalAccount.internal();
  }
}

type SubstrateProxyProviderOptions =
  | PolkadotExtensionAccountProvider
  | SuperheroAccountProvider
  | PasskeyAccountProvider;

export class SubstrateProxyAccountProvider
  implements AccountProvider<InjectedAccountWithMeta>
{
  public type: AccountType = 'substrate';

  // TODO: Temp
  public selectedAccount$: Observable<Account<any /* TODO: types */> | null>;

  // TODO: Temp
  public accounts$: ReplaySubject<Account<any /* TODO: types */>[] | null> =
    new ReplaySubject(1);

  private active: SubstrateProxyProviderOptions | undefined;
  private polkadotExtension: PolkadotExtensionAccountProvider;
  private superhero: SuperheroAccountProvider;
  private passkey: PasskeyAccountProvider;

  constructor(network: NetworkService, tabSync: TabSyncService) {
    this.polkadotExtension = new PolkadotExtensionAccountProvider();
    this.superhero = new SuperheroAccountProvider(network, tabSync);
    this.passkey = new PasskeyAccountProvider(network);
    this.polkadotExtension.accounts$.subscribe((account) =>
      this.accounts$.next(account)
    );
    this.selectedAccount$ = combineLatest([
      this.polkadotExtension.selectedAccount$,
      this.passkey.selectedAccount$,
      this.superhero.selectedAccount$,
    ]).pipe(
      map(([account1, account2, account3]) => account1 ?? account2 ?? account3)
    );
  }

  public async setActive(provider: SubstrateProxyProviderOptions) {
    this.active = provider;
  }

  public async connect(): Promise<void> {
    await Promise.all([
      this.polkadotExtension.connect(),
      this.superhero.connect(),
      this.passkey.connect(),
    ]);
    const selected = await firstValueFrom(this.selectedAccount$);
    if (selected === null) {
      return;
    }
    switch (selected.source) {
      case 'polkadotjs-extension':
        this.setActive(this.polkadotExtension);
        break;
      case 'passkey':
        this.setActive(this.passkey);
        break;
      case 'superhero':
        this.setActive(this.superhero);
        break;
      default:
        break;
    }
    if (this.active === undefined) {
      return;
    }
    this.active.connect();
  }

  public async disconnect(): Promise<void> {
    if (this.active === undefined) {
      return;
    }
    this.active.disconnect();
    this.active = undefined;
  }

  public async setSelectedAccount(
    account: Account<InjectedAccountWithMeta> | null
  ) {
    this.setActive(this.polkadotExtension);
    if (!this.active) {
      throw new Error('SubstrateProxyAccountProvider not initialized');
    }
    this.active.setSelectedAccount(account);
  }

  public async signMessage(
    message: string,
    account: GetAccount<'substrate'>,
    api: ApiPromise
  ): Promise<string | undefined> {
    if (!this.active) {
      throw new Error('SubstrateProxyAccountProvider not initialized');
    }
    return this.active.signMessage(message, account as any, api); // TODO: fix typings
  }

  public async requestAccount(source: AccountSource) {
    if (source === 'polkadotjs-extension') {
      return Promise.resolve();
      // NOOP
    } else if (source === 'superhero') {
      const response = await this.superhero.requestAccount();
      this.setActive(this.superhero);
      return response;
    } else if (source === 'passkey') {
      const response = await this.passkey.requestAccount();
      this.setActive(this.passkey);
      return response;
    }

    throw new Error(`Unknown Account Source "${source}"`);
  }

  public async getSigner(api: ApiPromise): Promise<Signer | undefined> {
    if (!this.active) {
      throw new Error('SubstrateProxyAccountProvider not initialized');
    }
    return this.active.getSigner(api);
  }
}
