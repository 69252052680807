import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'acurast-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() class: string = '';
  @Input() label: string = '';
  @Input() showSpinner: boolean = false;
  @Input() disabled: boolean = false;

  @Output() clicked = new EventEmitter();

  onClick() {
    this.clicked.emit();
  }
}
