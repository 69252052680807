import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export type AcurastNotification = {
  id: string;
  type: 'success' | 'error';
  title?: string;
  message?: string;
};

@Component({
  selector: 'acurast-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  animations: [
    trigger('opacityTranslateY', [
      transition(':enter', [
        style({ transform: 'translateY(0.5rem)', opacity: 0 }),
        animate(
          '300ms ease-out',
          style({ transform: 'translateY(0)', opacity: 1 })
        ),
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0)', opacity: 1 }),
        animate(
          '100ms ease-in',
          style({ transform: 'translateY(0.5rem)', opacity: 0 })
        ),
      ]),
    ]),
  ],
})
export class NotificationComponent {
  @Input() notification?: AcurastNotification;

  @Output() onClose = new EventEmitter<AcurastNotification>();

  constructor() {}

  public close() {
    if (this.notification) {
      this.onClose.emit(this.notification);
    }
  }
}
