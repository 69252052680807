<div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
  <div class="sm:flex sm:items-center">
    <div class="sm:flex-auto">
      <h1 class="text-2xl font-semibold leading-6 text-white">Leaderboard</h1>
      <p class="mt-2 text-sm text-white">
        Highest ranked users by earned MIST.
      </p>
    </div>
  </div>
  <div class="mt-8 flow-root">
    <div class="-mx-4 -my-2 overflow-x-auto text-md sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <table class="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                class="py-3.5 pl-4 pr-3 text-left font-semibold text-primary-500 sm:pl-0"
              >
                Ranking
              </th>
              <th
                scope="col"
                class="px-3 py-3.5 text-left font-semibold text-primary-900"
              >
                MIST
              </th>
              <th
                scope="col"
                class="px-3 py-3.5 text-left font-semibold text-primary-900"
              >
                Player
              </th>
              <th
                scope="col"
                class="px-3 py-3.5 text-left font-semibold text-primary-900"
              >
                Rank
              </th>
              <th
                scope="col"
                class="px-3 py-3.5 text-left font-semibold text-primary-900"
              >
                Processors
              </th>
              <th
                scope="col"
                class="px-3 py-3.5 text-left font-semibold text-primary-900"
              >
                Invited
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-500">
            <ng-container *ngFor="let item of leaderboard$ | async">
              <tr leaderboard-item [data]="item"></tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
