<!-- Global notification live region, render this permanently at the end of the document -->
<div
  *ngIf="notification"
  aria-live="assertive"
  class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
>
  <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
    <div
      class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
    >
      <div class="p-4">
        <div class="flex items-start">
          <div class="flex-shrink-0">
            <!-- <check-circle-outline-icon
                            *ngIf="notification.type === 'success'"
                            class="text-green-400"
                        ></check-circle-outline-icon>

                        <check-circle-outline-icon
                            *ngIf="notification.type === 'error'"
                            class="text-red-400"
                        ></check-circle-outline-icon> -->
          </div>
          <div class="ml-3 w-0 flex-1 pt-0.5">
            <p
              *ngIf="notification.title"
              class="text-sm font-medium text-gray-900"
            >
              {{ notification.title }}
            </p>
            <p
              *ngIf="notification.message"
              class="mt-1 text-sm text-gray-500 break-words"
            >
              {{ notification.message }}
            </p>
          </div>
          <div class="ml-4 flex flex-shrink-0">
            <button
              type="button"
              (click)="close()"
              class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
            >
              <span class="sr-only">Close</span>
              <acurast-icon variant="close"></acurast-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
