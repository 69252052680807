<div class="bg-black w-screen h-screen">
  <div class="w-screen h-screen">
    <div class="navbar" *ngIf="!isHomeRoute()">
      <app-navbar></app-navbar>
    </div>
    <router-outlet></router-outlet>
    <app-footer *ngIf="!isHomeRoute()"></app-footer>
  </div>
</div>

<acurast-notification
  *ngFor="let notification of notifications"
  [notification]="notification"
  (onClose)="onClose($event)"
></acurast-notification>
