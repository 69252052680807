<img
  *ngIf="account.type === 'tezos' && identicon"
  class="w-6 h-6 m-1"
  [src]="identicon"
/>
<polkadot-angular-identicon
  *ngIf="account.type === 'substrate'"
  [address]="account.address()"
  theme="jdenticon"
  size="32"
></polkadot-angular-identicon>
