<div class="sm:flex justify-between pt-5 pb-5">
    <div *ngIf="protocol !== undefined || header !== undefined" class="flex items-center gap-4 pb-3 sm:pb-0">
        <img
            [src]="protocol ? '/assets/svg/' + protocol + '_symbol.svg' : '/assets/svg/' + header?.icon + '.svg'"
            class="w-10 h-10 rounded-full"
        />
        <h3 class="font-bold capitalize">{{ protocol ?? header?.name }}</h3>
    </div>

    <div class="flex">
        <div class="flex items-center p-2 gap-2 bg-slate-100 rounded-md">
            <img src="/assets/acurast-consumer-symbol.png" class="w-4 h-4" />
            <p><small>Consumer</small></p>
        </div>
        <div class="flex items-center p-2 ml-2 gap-2 bg-slate-100 rounded-md" [class.opacity-50]="processorDeactivated">
            <img src="/assets/acurast-processor-symbol.png" class="w-4 h-4" />
            <p><small>Processor</small></p>
        </div>
    </div>
</div>
