<nav class="bg-black">
  <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
    <div class="relative flex h-16 items-center justify-between">
      <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
        <!-- Mobile menu button-->
        <button
          type="button"
          class="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
          aria-controls="mobile-menu"
          aria-expanded="false"
        >
          <span class="absolute -inset-0.5"></span>
          <span class="sr-only">Open main menu</span>
          <!--
              Icon when menu is closed.
  
              Menu open: "hidden", Menu closed: "block"
            -->
          <svg
            class="block h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
          <!--
              Icon when menu is open.
  
              Menu open: "block", Menu closed: "hidden"
            -->
          <svg
            class="hidden h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div
        class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start"
      >
        <div class="flex flex-shrink-0 items-center">
          <a class="flex items-center" href="/">
            <img
              class="h-8 w-auto"
              src="/assets/img/Acurast-dark-bg.svg"
              alt="Cloud Rebellion - Acurast"
            />
            <p class="text-white">Cloud Rebellion</p>
          </a>
        </div>
      </div>
      <div
        class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"
      >
        <div class="flex space-x-4">
          <a
            routerLink="/dashboard"
            routerLinkActive="active"
            class="text-white rounded-md px-3 py-2 text-sm font-medium hover:text-primary-900"
            aria-current="page"
            >Quests</a
          >
          <a
            routerLink="/leaderboard"
            routerLinkActive="active"
            class="text-white rounded-md px-3 py-2 text-sm font-medium"
            aria-current="page"
            >Leaderboard</a
          >
          <button
            *ngIf="!isLoggedIn"
            (click)="connectModalOpen = !connectModalOpen"
            type="button"
            class="rounded-md bg-primary-900 px-2.5 py-1.5 text-sm font-semibold text-black shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500"
          >
            Log in
          </button>
          <button
            *ngIf="isLoggedIn"
            (click)="disconnect()"
            type="button"
            class="rounded-md bg-primary-900 px-2.5 py-1.5 text-sm font-semibold text-black shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500"
          >
            Disconnect
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Mobile menu, show/hide based on menu state. -->
  <div class="sm:hidden" id="mobile-menu">
    <div class="space-y-1 px-2 pb-3 pt-2">
      <a
        routerLink="/dashboard"
        class="text-white rounded-md px-3 py-2 text-sm font-medium"
        aria-current="page"
        >Quests</a
      >
      <a
        routerLink="/leaderboard"
        class="text-white rounded-md px-3 py-2 text-sm font-medium"
        aria-current="page"
        >Leaderboard</a
      >
    </div>
  </div>
</nav>

<app-modal [open]="loginModalOpen" (onClickClose)="toggleLoginModalOpen()">
  <app-login-modal> </app-login-modal>
</app-modal>

<acurast-account-selection-modal
  [open]="connectModalOpen"
  [allowedAccountTypes]="allowedAccountTypes"
  (onSelectedAccount)="selectAccount($event)"
  (onClose)="onCloseModal()"
>
</acurast-account-selection-modal>
