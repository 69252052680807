<td
  class="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-primary-900 sm:pl-0"
>
  #{{ data?.leaderboardPosition }}
</td>
<td class="whitespace-nowrap px-3 py-4 text-white">
  {{ data?.balanceWithReferrals }}
</td>
<td class="whitespace-nowrap px-3 py-4 text-white">
  {{ data?.managers?.[0] }}
</td>
<td class="whitespace-nowrap px-3 py-4 text-white">
  <div class="flex align-middle">
    <app-rank-item [rankName]="'Bolt Private'"></app-rank-item>
    <!--TODO: Rank-->
    <span class="ml-3"></span>
  </div>
</td>
<td class="whitespace-nowrap px-3 py-4 text-white">
  {{ data?.nofProcessors }}
</td>
<td class="whitespace-nowrap px-3 py-4 text-white">{{ data?.nofReferred }}</td>
