import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/services/account/account.service';
import { ApiService } from 'src/app/services/api/api.service';
import { ClipboardService } from 'src/app/services/clipboard/clipboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public referralModalOpen: boolean = false;
  public rankModalOpen: boolean = false;

  public user: any | undefined;

  public loadingUserData: boolean = false;

  constructor(
    private readonly api: ApiService,
    private readonly clipboard: ClipboardService,
    private readonly router: Router
  ) {
    console.log('REGISTER LISTENER  ');

    this.api.user$.subscribe((user) => {
      console.log('HAS USER', user);
      if (user) {
        this.user = user;
      }
    });
  }

  async ngOnInit(): Promise<void> {
    await this.getUserData();
  }

  private async getUserData() {
    console.log('Getting user data');
    const referralCode = localStorage.getItem('referralCode');
    const selectedAccount = localStorage.getItem('selectedAccount');

    if (!selectedAccount && !referralCode) {
      console.log('REDIRECT');
      this.router.navigate(['/']);
      return;
    }

    if (!selectedAccount) {
      console.log('DOING NOTHING, WAITING FOR ACCOUNT');
      return;
    }

    console.log('Getting user data with', selectedAccount, referralCode);
    this.loadingUserData = true;
    await this.api.getMyUser(selectedAccount);
    this.loadingUserData = false;
    console.log('User:', this.user);
  }

  public toggleReferralModalOpen() {
    this.referralModalOpen = !this.referralModalOpen;
  }

  public toggleRankModalOpen() {
    this.rankModalOpen = !this.rankModalOpen;
  }

  public copyReferralCode() {
    this.clipboard.copy(this.user.referralCode);
  }
}
