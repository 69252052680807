import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @Input() open: boolean = false;
  @Output() onClickClose = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onClose(event: any) {
    var target = event.target || event.srcElement || event.currentTarget;
    if (target.id === 'acurast-modal-outside') {
      this.onClickClose.emit();
    }
  }
}
