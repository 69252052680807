import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'acurast-account-selection-modal-heading',
    templateUrl: './account-selection-modal-heading.component.html',
    styleUrls: ['./account-selection-modal-heading.component.scss'],
})
export class AccountSelectionModalHeadingComponent implements OnInit {
    @Input() protocol: string | undefined;
    @Input() header: { icon: string; name: string } | undefined;
    @Input() processorDeactivated: boolean = false;

    constructor() {}

    ngOnInit(): void {}
}
