import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TabSyncService {
  private listeners: Map<Function, (event: StorageEvent) => void> = new Map();
  constructor() {}

  send(object: unknown) {
    // TODO: We can replace this with BroadcastChannels to have better browser compatibility, but needs testing.
    localStorage.setItem('tab-sync', JSON.stringify(object));
  }

  addListener(fn: (object: any) => void) {
    const inlineFn = function (event: StorageEvent) {
      if (event.key === 'tab-sync') {
        const message = JSON.parse(event.newValue ?? '');
        localStorage.removeItem('tab-sync');
        fn(message);
      }
    };

    this.listeners.set(fn, inlineFn);

    window.addEventListener('storage', inlineFn);
  }

  removeListener(fn: (object: any) => void) {
    const inlineFn = this.listeners.get(fn);

    if (!inlineFn) {
      return;
    }

    window.removeEventListener('storage', inlineFn);
  }
}
