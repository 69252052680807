import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
})
export class LoginModalComponent implements OnInit {
  @Output() onClick = new EventEmitter<'login'>();
  constructor() {}

  ngOnInit(): void {}

  loginWithWallet() {
    console.log('Login with wallet');
  }
}
