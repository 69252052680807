import {
  Account,
  SignatureType,
  AccountProvider,
  AccountSource,
  AccountType,
} from './account.provider';
import { ReplaySubject, withLatestFrom } from 'rxjs';
import { NetworkService } from '../network/network.service';
import { TabSyncService } from '../tab-sync/tab-sync.service';
import { encode as varuintEncode } from 'varuint-bitcoin';
import { Signer } from '@polkadot/api/types';
import {
  SignerPayloadRaw,
  SignerResult,
} from '@polkadot/types/types/extrinsic';
import { ApiPromise } from '@polkadot/api';
import * as blake from 'blakejs';
import * as bs58check from 'bs58check';

const base58 = require('base58-js');

export interface SuperheroWalletAddressResponse {
  address: string;
  networkId: string;
}

export interface SuperheroWalletSignResponse {
  signature: string;
}

const SUPERHERO_WALLET_URL = 'https://wallet.superhero.com';

const SUPERHERO_STORAGE_KEY = 'acurast-account-superhero';

export class SuperHeroAccount
  implements Account<SuperheroWalletAddressResponse>
{
  public type: AccountType = 'substrate';
  public source: AccountSource = 'superhero';
  public signatureType: SignatureType = 'ed25519WithPrefix';
  private internalAccount: SuperheroWalletAddressResponse;

  constructor(account: SuperheroWalletAddressResponse) {
    this.internalAccount = structuredClone(account);
  }

  public address(): string {
    const publicKey = bs58check.decode(
      this.internalAccount.address.substring(3)
    );
    const substrateId = new Uint8Array([42]);

    var body = new Uint8Array(substrateId.length + publicKey.length);
    body.set(substrateId);
    body.set(publicKey, substrateId.length);

    const prefix = Buffer.from('SS58PRE', 'utf8');

    var context = blake.blake2bInit(64);

    blake.blake2bUpdate(context, prefix);
    blake.blake2bUpdate(context, body);

    var checksum = blake.blake2bFinal(context);

    var address = new Uint8Array(body.length + 2);
    address.set(body);
    address.set(checksum.slice(0, 2), body.length);

    return base58.binary_to_base58(address);
  }

  public name(): string | undefined {
    return undefined;
  }

  public internal(): SuperheroWalletAddressResponse {
    return this.internalAccount;
  }
}

export class SuperheroAccountProvider
  implements AccountProvider<SuperheroWalletAddressResponse>
{
  public type: AccountType = 'substrate';
  public selectedAccount$: ReplaySubject<Account<SuperheroWalletAddressResponse> | null> =
    new ReplaySubject(1);

  constructor(
    network: NetworkService,
    private readonly tabSync: TabSyncService
  ) {
    this.selectedAccount$.next(null);
  }

  public async connect(): Promise<void> {
    const superhero: SuperheroWalletAddressResponse = JSON.parse(
      localStorage.getItem(SUPERHERO_STORAGE_KEY) ?? '{}'
    );
    if (superhero.address) {
      const account = new SuperHeroAccount(superhero);
      this.selectedAccount$.next(account);
    }
  }

  public async disconnect(): Promise<void> {
    localStorage.removeItem(SUPERHERO_STORAGE_KEY);
    this.selectedAccount$.next(null);
  }

  public async requestAccount(): Promise<Account<SuperheroWalletAddressResponse> | null> {
    const successUrl = `${getBaseRedirectUrl()}/callback?result=success&address={address}&networkId={networkId}`;
    const cancelUrl = `${getBaseRedirectUrl()}/callback?result=error`;
    window.open(
      `${SUPERHERO_WALLET_URL}/address?x-success=${encodeURIComponent(
        successUrl
      )}&x-cancel=${encodeURIComponent(cancelUrl)}`,
      '_blank'
    );

    return new Promise((resolve) => {
      const returnFn = (response: SuperheroWalletAddressResponse) => {
        const account = new SuperHeroAccount(response);
        localStorage.setItem(SUPERHERO_STORAGE_KEY, JSON.stringify(response));
        this.tabSync.removeListener(returnFn);
        this.selectedAccount$.next(account);
        resolve(account);
      };

      this.tabSync.addListener(returnFn);
    });
  }

  public async setSelectedAccount(account: Account<any> | null) {
    // NOOP
  }

  public async signMessage(
    message: string,
    account: Account<SuperheroWalletAddressResponse>,
    api: ApiPromise
  ): Promise<string | undefined> {
    const signer = await this.getSigner(api);
    const messageBytes = Buffer.from(
      message.startsWith('0x') ? message.slice(2) : message,
      'hex'
    );
    const fullMessage = Buffer.concat([
      Buffer.from('<Bytes>', 'utf8'),
      messageBytes,
      Buffer.from('</Bytes>', 'utf8'),
    ]).toString('hex');

    const result = await signer.signRaw!({
      type: 'bytes',
      data: `0x${fullMessage}`,
      address: account.address(),
    });
    return result.signature;
  }

  public async getSigner(api: ApiPromise): Promise<Signer> {
    return new SuperheroSigner(api);
  }
}

const AE_PREFIX = Buffer.from('aeternity Signed Message:\n', 'utf8');

export class SuperheroSigner implements Signer {
  constructor(private readonly api: ApiPromise) {}

  async signRaw(raw: SignerPayloadRaw): Promise<SignerResult> {
    const tabSync = new TabSyncService();
    const data = raw.data.startsWith('0x') ? raw.data.slice(2) : raw.data;

    const successUrl = `${getBaseRedirectUrl()}/callback?result=success&signature={signature}`;
    const cancelUrl = `${getBaseRedirectUrl()}/callback?result=error`;
    window.open(
      `${SUPERHERO_WALLET_URL}/sign-message?x-success=${encodeURIComponent(
        successUrl
      )}&x-cancel=${encodeURIComponent(
        cancelUrl
      )}&encoding=hex&message=${data}`,
      '_blank'
    );

    return new Promise((resolve) => {
      const returnFn = (response: SuperheroWalletSignResponse) => {
        tabSync.removeListener(returnFn);

        const signature = (response as any).signature;
        const sigBuf = Buffer.from(signature, 'hex');

        function computePrefix(message: string): Buffer {
          const messageBuf = Buffer.from(message, 'hex');
          const buf = Buffer.concat([
            varuintEncode(AE_PREFIX.length),
            AE_PREFIX,
            varuintEncode(messageBuf.length),
          ]);
          return buf;
        }

        const messagePrefix = computePrefix(data);
        const scaleLength = this.api.createType(
          'Compact<u8>',
          messagePrefix.length
        );

        const extendedSig = Buffer.concat([
          raw.type === 'payload' ? new Uint8Array([5]) : new Uint8Array(),
          sigBuf,
          scaleLength.toU8a(),
          messagePrefix,
        ]);

        resolve({
          id: 0,
          signature: `0x${extendedSig.toString('hex')}`,
        });
      };

      tabSync.addListener(returnFn);
    });
  }
}

function getBaseRedirectUrl(): string {
  return window.location.origin;
}
