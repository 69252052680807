import { Account, SignatureType, AccountProvider, AccountSource, AccountType } from './account.provider';
import { ReplaySubject } from 'rxjs';
import { NetworkService } from '../network/network.service';
import { Signer } from '@polkadot/api/types';
import { SignerPayloadRaw, SignerResult } from '@polkadot/types/types/extrinsic';
import { ApiPromise } from '@polkadot/api';
import * as blake from 'blakejs';
import { InjectedAccountWithMeta } from '@polkadot/extension-inject/types';
import { ec as EC } from 'elliptic';
import { randomBytes } from 'crypto';
const base58 = require('base58-js');
const base64url = require('base64-url');

const PASSKEY_ACCOUNT_KEY = 'acurast-account-passkey';
const PASSKEY_ACCOUNT_IS_ACTIVE_KEY = 'acurast-account-passkey-is-active';

const P256 = new EC('p256');

export interface PasskeyAccountData {
    rawId: string;
    pubKey: string;
}

export class PasskeyAccount implements Account<PasskeyAccountData> {
    public type: AccountType = 'substrate';
    public source: AccountSource = 'passkey';
    public signatureType: SignatureType = 'p256WithAuthData';
    private internalAccount: PasskeyAccountData;
    private _address: string;

    constructor(account: PasskeyAccountData) {
        this.internalAccount = account;
        this._address = computeAddress(Buffer.from(account.pubKey, 'hex'));
    }

    public address(): string {
        return this._address;
    }

    public name(): string | undefined {
        return undefined;
    }

    public internal(): PasskeyAccountData {
        return this.internalAccount;
    }
}

export class PasskeyAccountProvider implements AccountProvider<PasskeyAccountData> {
    public type: AccountType = 'substrate';
    public selectedAccount$: ReplaySubject<Account<PasskeyAccountData> | null> = new ReplaySubject(1);

    constructor(private readonly network: NetworkService) {
        this.selectedAccount$.next(null);
    }

    public async connect(): Promise<void> {
        const account = readAccount();
        if (isActive() && account !== null) {
            this.selectedAccount$.next(account);
            setIsActive(true);
        }
    }

    public async disconnect(): Promise<void> {
        setIsActive(false);
        this.selectedAccount$.next(null);
    }

    public async requestAccount(): Promise<Account<PasskeyAccountData> | null> {
        let account = readAccount();
        if (account === null) {
            account = await this.generateAccount();
            storeAccount(account);
        }
        this.selectedAccount$.next(account);
        setIsActive(true);
        return account;
    }

    private async generateAccount(
        userId: string = 'com.acurast.user',
        challenge: Buffer = randomBytes(32),
    ): Promise<PasskeyAccount> {
        const credentials = await navigator.credentials.create({
            publicKey: {
                challenge,
                rp: {
                    id: window.location.hostname,
                    name: 'Acurast',
                },
                user: {
                    id: Buffer.from(userId, 'utf8'),
                    name: 'Acurast Console',
                    displayName: 'Acurast Console',
                },
                authenticatorSelection: {
                    userVerification: 'required' /* was preferred */,
                },
                attestation: 'direct',
                pubKeyCredParams: [
                    {
                        type: 'public-key',
                        alg: -7, // "ES256" IANA COSE Algorithms registry
                    },
                ],
            },
        });
        if (credentials === null) {
            throw Error('Cannot create credentials');
        }
        const response = (credentials as PublicKeyCredential).response as AuthenticatorAttestationResponse;
        const spki = (response as any).getPublicKey();
        if (spki === null) {
            throw Error('Cannot get public key info');
        }
        return new PasskeyAccount({
            rawId: Buffer.from((credentials as PublicKeyCredential).rawId).toString('hex'),
            pubKey: (await convertSubjectPublicKeyInfoToCompressedKey(Buffer.from(spki))).toString('hex'),
        });
    }

    public async setSelectedAccount(account: Account<InjectedAccountWithMeta> | null) {
        // NOOP
    }

    public async signMessage(
        message: string,
        account: Account<PasskeyAccount>,
        api: ApiPromise,
    ): Promise<string | undefined> {
        const signer = await this.getSigner(api);
        const messageBytes = Buffer.from(message.startsWith('0x') ? message.slice(2) : message, 'hex');
        const fullMessage = Buffer.concat([
            Buffer.from('<Bytes>', 'utf8'),
            messageBytes,
            Buffer.from('</Bytes>', 'utf8'),
        ]).toString('hex');

        const result = await signer.signRaw!({
            type: 'bytes',
            data: `0x${fullMessage}`,
            address: account.address(),
        });
        return result.signature;
    }

    public async getSigner(api: ApiPromise): Promise<Signer> {
        return new PasskeySigner(api);
    }
}

export class PasskeySigner implements Signer {
    constructor(private readonly api: ApiPromise) {}

    async signRaw(raw: SignerPayloadRaw): Promise<SignerResult> {
        const data = raw.data.startsWith('0x') ? raw.data.slice(2) : raw.data;
        const account = readAccount();
        if (account === null || account.address() !== raw.address) {
            throw Error('No passkey account found');
        }

        const challenge = await prepareMessage(Buffer.from(data, 'hex'));
        const credentials = await navigator.credentials.get({
            publicKey: {
                challenge,
                allowCredentials: [
                    {
                        id: Buffer.from(account.internal().rawId, 'hex'),
                        type: 'public-key',
                    },
                ],
                timeout: 15000,
                userVerification: 'required' /* was preferred */,
            },
        });

        if (credentials === null) {
            throw Error('Cannot get credentials');
        }

        const response = (credentials as PublicKeyCredential).response as AuthenticatorAssertionResponse;
        const authData = Buffer.from(response.authenticatorData);
        const signature = extractSignature(response.signature);

        const clientDataHash = Buffer.from(await crypto.subtle.digest('SHA-256', response.clientDataJSON));
        const messageHash = Buffer.from(
            await crypto.subtle.digest('SHA-256', Buffer.concat([authData, clientDataHash])),
        );
        const recoveryId = findRecoveryId(messageHash, signature, account.internal().pubKey);
        const recoverableSignature = Buffer.concat([signature, new Uint8Array([recoveryId])]);
        const authDataLength = this.api.createType('Compact<u8>', authData.byteLength);

        const base64EncodedMessage = Buffer.from(base64url.escape(base64url.encode(challenge)), 'utf8');
        const challengeKey = Buffer.from('"challenge":"', 'utf8');
        const challengeIndex = Buffer.from(response.clientDataJSON).indexOf(
            Buffer.concat([challengeKey, base64EncodedMessage]),
        );
        if (challengeIndex < 0) {
            throw Error('Unexpected Client Data');
        }
        const clientDataPrefix = Buffer.from(
            response.clientDataJSON.slice(0, challengeIndex + challengeKey.byteLength),
        );
        const clientDataPostfix = Buffer.from(
            response.clientDataJSON.slice(challengeIndex + challengeKey.byteLength + base64EncodedMessage.byteLength),
        );
        const clientDataPrefixLength = this.api.createType('Compact<u32>', clientDataPrefix.byteLength);
        const clientDataPostfixLength = this.api.createType('Compact<u32>', clientDataPostfix.byteLength);

        const extendedSig = Buffer.concat([
            raw.type === 'payload' ? new Uint8Array([4]) : new Uint8Array(),
            recoverableSignature,
            authDataLength.toU8a(),
            authData,
            new Uint8Array([1]),
            clientDataPrefixLength.toU8a(),
            clientDataPrefix,
            clientDataPostfixLength.toU8a(),
            clientDataPostfix,
        ]);

        return {
            id: 0,
            signature: `0x${extendedSig.toString('hex')}`,
        };
    }
}

function readAccount(): PasskeyAccount | null {
    const storedAccountData = localStorage.getItem(PASSKEY_ACCOUNT_KEY);
    if (storedAccountData !== null) {
        const passkey: PasskeyAccountData = JSON.parse(storedAccountData);
        if (passkey.pubKey !== undefined && passkey.rawId !== undefined) {
            return new PasskeyAccount(passkey);
        }
    }
    return null;
}

function storeAccount(account: PasskeyAccount) {
    localStorage.setItem(PASSKEY_ACCOUNT_KEY, JSON.stringify(account.internal()));
}

function setIsActive(isActive: boolean) {
    localStorage.setItem(PASSKEY_ACCOUNT_IS_ACTIVE_KEY, JSON.stringify(isActive));
}

function isActive(): boolean {
    const value = localStorage.getItem(PASSKEY_ACCOUNT_IS_ACTIVE_KEY);
    if (value !== null) {
        return JSON.parse(value);
    }
    return false;
}

// Function to compress a P-256 public key
function compressPublicKey(pubKeyHex: string): string {
    // Decode the hex string to a public key object
    const key = P256.keyFromPublic(pubKeyHex, 'hex');

    // Get the compressed form of the public key
    const compressedKey = key.getPublic().encodeCompressed('hex');

    return compressedKey;
}

async function convertSubjectPublicKeyInfoToCompressedKey(spkiPubKey: Buffer): Promise<Buffer> {
    const cryptoKey = await crypto.subtle.importKey(
        'spki',
        spkiPubKey,
        {
            name: 'ECDSA',
            namedCurve: 'P-256',
        },
        true,
        ['verify'],
    );
    const rawKey = await crypto.subtle.exportKey('raw', cryptoKey);
    return Buffer.from(compressPublicKey(Buffer.from(rawKey).toString('hex')), 'hex');
}

function computeAddress(compressedKey: Buffer): string {
    const publicKeyHash = blake.blake2b(compressedKey, undefined, 32);
    const substrateId = new Uint8Array([42]);
    var body = new Uint8Array(substrateId.length + publicKeyHash.length);
    body.set(substrateId);
    body.set(publicKeyHash, substrateId.length);

    const prefix = Buffer.from('SS58PRE', 'utf8');

    var context = blake.blake2bInit(64);

    blake.blake2bUpdate(context, prefix);
    blake.blake2bUpdate(context, body);

    var checksum = blake.blake2bFinal(context);

    var address = new Uint8Array(body.length + 2);
    address.set(body);
    address.set(checksum.slice(0, 2), body.length);

    return base58.binary_to_base58(address);
}

function extractSignature(encodedSignatureBuffer: ArrayBuffer): Uint8Array {
    // Create a DataView for easier access to the ArrayBuffer
    const view = new DataView(encodedSignatureBuffer);
    let offset = 0;

    // Check for sequence marker (0x30)
    if (view.getUint8(offset++) !== 0x30) {
        throw new Error('Invalid ECDSA signature format');
    }

    // Length of the sequence
    let sequenceLength = view.getUint8(offset++);
    if (sequenceLength & 0x80) {
        // Long form length
        const lengthOfLength = sequenceLength & 0x7f;
        sequenceLength = 0;
        for (let i = 0; i < lengthOfLength; ++i) {
            sequenceLength = (sequenceLength << 8) | view.getUint8(offset++);
        }
    }

    // Extracting r
    if (view.getUint8(offset++) !== 0x02) {
        // Integer marker
        throw new Error('Invalid ECDSA signature format for r');
    }

    let rLength = view.getUint8(offset++);
    // Handle leading zero bytes for r
    while (view.getUint8(offset) === 0x00 && rLength > 32) {
        offset++;
        rLength--;
    }

    const r = new Uint8Array(encodedSignatureBuffer, offset, rLength);
    offset += rLength;

    // Extracting s
    if (view.getUint8(offset++) !== 0x02) {
        // Integer marker
        throw new Error('Invalid ECDSA signature format for s');
    }

    let sLength = view.getUint8(offset++);
    // Handle leading zero bytes for s
    while (view.getUint8(offset) === 0x00 && sLength > 32) {
        offset++;
        sLength--;
    }

    const s = new Uint8Array(encodedSignatureBuffer, offset, sLength);

    const combinedLength = rLength + sLength;
    const combinedView = new Uint8Array(combinedLength);

    combinedView.set(r, 0);
    combinedView.set(s, rLength);

    return combinedView;
}

async function prepareMessage(message: Uint8Array): Promise<Uint8Array> {
    if (message.length > 256) {
        return blake.blake2b(message, undefined, 32);
    }
    if (message.length != 32) {
        return new Uint8Array(await crypto.subtle.digest('SHA-256', message));
    }
    return message;
}

function findRecoveryId(hash: Uint8Array, signature: Uint8Array, originalPublicKey: string): number {
    const r = signature.subarray(0, 32);
    const s = signature.subarray(32);
    for (let recoveryId = 0; recoveryId < 4; recoveryId++) {
        try {
            const recoveredKey = P256.recoverPubKey(hash, { r, s }, recoveryId);
            const compressedKey = P256.keyFromPublic(recoveredKey).getPublic().encodeCompressed('hex');
            if (compressedKey === originalPublicKey) {
                return recoveryId; // Correct recoveryId found
            }
        } catch (error) {
            throw Error('Cannot find recovery ID');
        }
    }
    throw Error('Cannot find recovery ID');
}
