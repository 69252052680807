<div
  *ngIf="!user && !loadingUserData"
  class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"
>
  <div class="sm:flex sm:items-center">
    <div class="sm:flex-auto">
      <h1 class="text-2xl font-semibold leading-6 text-white pb-6">
        Please log in to continue.
      </h1>
    </div>
  </div>
</div>
<div *ngIf="loadingUserData" class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
  <div class="sm:flex sm:items-center">
    <div class="sm:flex-auto">
      <h1 class="text-2xl font-semibold leading-6 text-white pb-6">
        Loading...
      </h1>
    </div>
  </div>
</div>
<div *ngIf="user" class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
  <div class="sm:flex sm:items-center">
    <div class="sm:flex-auto">
      <h1 class="text-2xl font-semibold leading-6 text-white pb-6">
        Welcome to the
        <span class="text-primary-900">Cloud Rebellion</span>
      </h1>
    </div>
  </div>

  <div
    class="overflow-hidden border border-gray-50 rounded-lg shadow w-full h-full overflow-y-auto mb-3 bg-gradient"
  >
    <div class="text-white px-4 py-5 sm:px-6">
      <div class="flex justify-between items-center gap-4">
        <div>
          <h3 class="text-base font-bold leading-6 pb-3">
            Season 1<span class="mx-3 text-gray-400">/</span
            ><span class="text-gray-600">Season 2</span
            ><span class="mx-3 text-gray-400">/</span
            ><span class="text-gray-600">Season 3</span>
          </h3>
          <p class="max-w-prose text-gray-300">
            Use dedicated mobile phones and onboard them as Processors,
            providing compute for Acurast's decentralized cloud. Want to
            cloud-harvest more MIST? Use your referral code to grow the Cloud
            Rebellion and Acurast's decentralized cloud.
          </p>
          <p class="pt-3 text-gray-300">Upcoming:</p>
          <ul class="list-disc ml-4 text-gray-300">
            <li>Quests</li>
            <li>Ranks</li>
          </ul>
          <div>
            <p class="py-3">
              Season 2 starts in
              <span class="font-bold">30 d 20 h 10 m</span>
            </p>
            <div class="progress--container w-full">
              <div
                class="progress--steps bg-primary-900"
                [ngStyle]="{ width: '1%' }"
              ></div>
            </div>
          </div>
        </div>

        <div class="season--container" width="80px" height="80px">
          <img src="/assets/img/season-1.png" />
          <div
            class="season--container__bg"
            style="background-image: url(/assets/img/season-1.png)"
          ></div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex flex-col md:flex-row md:space-x-4 mb-8">
    <div
      class="overflow-hidden border border-gray-50 rounded-lg shadow w-full h-full overflow-y-auto mb-3 bg-gradient"
    >
      <div class="px-4 py-5 sm:px-6">
        <h3 class="text-base font-semibold leading-6 text-white">Details</h3>
      </div>
      <div class="p-4 sm:px-6 text-white">
        <div class="flex items-center">
          <h3 class="text-2xl">{{ user?.nofProcessors }} Processors</h3>
          <img
            src="/assets/img/acurast-processor-symbol.png"
            class="rounded-full ml-1"
            width="32px"
            height="32px"
          />
        </div>
      </div>
      <div class="p-4 sm:px-6 text-white">
        <div class="flex items-center">
          <h3 class="text-2xl">
            #{{ user?.leaderboardPosition }}
            <span class="text-gray-500">Leaderboard</span>
          </h3>
        </div>
      </div>
      <div class="flex justify-between p-4 pb-5 sm:px-6 text-white">
        <h3 class="text-2xl">{{ user?.balanceWithReferrals }} MIST ☁️</h3>
        <!--
        <h3 class="text-2xl opacity-25 px-2">/</h3>
        <div class="flex items-center">
          <h3 class="text-2xl">1401 cACU</h3>
          <img
            src="/assets/img/Acurast-symbol-dark-bg.png"
            class="rounded-full ml-1"
            width="24px"
            height="24px"
          />
        </div>
        -->
      </div>
    </div>
    <div
      class="overflow-hidden border border-gray-50 rounded-lg shadow w-full h-full overflow-y-auto mb-3 bg-gradient"
    >
      <div class="px-4 pt-5 sm:px-6">
        <h3
          class="font-semibold leading-6 text-white relative z-10 cursor-pointer"
          (click)="toggleRankModalOpen()"
        >
          Rank
          <fa-icon
            [icon]="['fas', 'circle-question']"
            class="text-primary-900 pl-1"
          ></fa-icon>
        </h3>
      </div>
      <div class="px-4 pb-5 sm:px-6">
        <div class="flex flex-row justify-between items-center">
          <div class="relative">
            <!--TODO: use current rank -->
            <p class="level--font text-white">LVL0</p>
            <h3 class="text-2xl text-white">Novice</h3>
          </div>
          <app-rank-item
            [size]="'large'"
            [rankName]="'Novice'"
            (click)="toggleRankModalOpen()"
          ></app-rank-item>
        </div>

        <div class="pt-4">
          <p class="text-gray-500 text-sm pb-2">Next Rank</p>
          <div class="flex flex-row justify-between items-start">
            <p class="text-white text-sm pb-3">Bolt Private</p>
            <app-rank-item [rankName]="'Bolt Private'"></app-rank-item>
          </div>
          <div class="progress--container w-full">
            <div
              class="progress--steps bg-primary-900"
              [ngStyle]="{ width: '1%' }"
            ></div>
          </div>
          <div class="flex flex-row justify-between text-sm text-white pt-3">
            Ranks are coming soon.
            <!--
            <p>1239 / 4000 MIST</p>
            -->
            <p>0%</p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="flex flex-row justify-between content-center overflow-hidden border border-gray-50 rounded-lg shadow w-full h-full overflow-y-auto mb-3 bg-gradient"
    >
      <div>
        <div class="px-4 py-5 sm:px-6">
          <h3 class="text-base font-semibold leading-6 text-white">
            Recruitments
          </h3>
        </div>
        <div class="px-4 py-5 sm:px-6">
          <button
            (click)="toggleReferralModalOpen()"
            type="button"
            class="rounded-md bg-black text-white px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:text-black hover:bg-primary-900"
          >
            Referral Code
          </button>
        </div>
      </div>

      <div class="p-2">
        <h3 class="text-8xl text-white">{{ user?.nofReferred }}</h3>
      </div>
    </div>
  </div>

  <div class="sm:flex sm:items-center py-8">
    <div class="sm:flex-auto">
      <h1 class="text-2xl font-semibold leading-6 text-white">Quests</h1>
    </div>
  </div>

  <div class="pb-8 mb-4">
    <h5 class="text-base uppercase leading-6 text-white pb-3">Introduction</h5>

    <app-quest-card
      [title]="'Welcome to the Cloud Rebellion'"
      [description]="'Learn about the Cloud Rebellion and Acurast.'"
      [pointsAmount]="'10'"
      [numberOfParticipants]="'65'"
    ></app-quest-card>
    <app-quest-card
      [title]="'Connect with Acurast'"
      [description]="
        'Keep up with the latest updates and follow Acurast on social channels.'
      "
      [questNumber]="'2'"
      [pointsAmount]="'10'"
      [numberOfParticipants]="'65'"
    ></app-quest-card>
  </div>

  <div class="pb-8 mb-4">
    <h5 class="text-base uppercase leading-6 text-white pb-3">Get Started</h5>
    <app-quest-card
      [title]="'Become part of the Rebellion: Processor'"
      [description]="'Onboard a phone and become an Acurast Processor.'"
      [questNumber]="'3'"
      [pointsAmount]="'10'"
      [numberOfParticipants]="'65'"
    ></app-quest-card>
    <app-quest-card
      [title]="'Become part of the Rebellion: Consumer'"
      [description]="
        'Deploy your first application to the decentralized cloud.'
      "
      [questNumber]="'4'"
      [pointsAmount]="'10'"
      [numberOfParticipants]="'65'"
    ></app-quest-card>
  </div>

  <div class="pb-8 mb-4">
    <h5 class="text-base uppercase leading-6 text-white pb-3">Processors</h5>
    <app-quest-card
      [title]="'Recruit for the Rebellion'"
      [description]="'Use your referal link to onboard other Processors.'"
      [questNumber]="'5'"
      [pointsAmount]="'10'"
      [numberOfParticipants]="'65'"
    ></app-quest-card>
  </div>

  <div class="pb-8 mb-4">
    <h5 class="text-base uppercase leading-6 text-white pb-3">Consumer</h5>
    <app-quest-card
      [title]="'Deploy for the Rebellion'"
      [description]="
        'Deploy a interval-based application to the decentralized cloud.'
      "
      [questNumber]="'6'"
      [pointsAmount]="'10'"
      [numberOfParticipants]="'65'"
    ></app-quest-card>
  </div>
</div>

<app-modal
  [open]="referralModalOpen"
  (onClickClose)="toggleReferralModalOpen()"
>
  <h3 class="text-2xl pb-5">Recruit for the Rebellion!</h3>
  <h5 class="pb-3">
    Use your referral code to recurit others to the Cloud Rebellion.
  </h5>
  <p>
    When they onboard a Processor with your referral code, you will receive
    <strong class="text-primary-900">25 MIST</strong>.
  </p>
  <p>
    As long as their Processor is online, you will receive
    <strong class="text-primary-900">10% in MIST</strong> of their hearbeat
    every 15 minutes.
  </p>
  <div class="pt-8">
    <h5
      *ngIf="!user?.referralCode"
      class="text-6xl pb-5 referral--code__hidden"
    >
      VS3FS5
    </h5>
    <h5 *ngIf="user?.referralCode" class="text-6xl pb-5">
      {{ user?.referralCode }}
    </h5>
    <p *ngIf="!user?.referralCode" class="pb-8">
      Your referral code will be visible after you have onboarded your first
      Processor.
    </p>
    <button
      type="button"
      (click)="copyReferralCode()"
      class="rounded-md bg-primary-900 text-black px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:text-black hover:bg-primary-500"
    >
      Copy Referral Code
    </button>
  </div>
</app-modal>

<app-modal [open]="rankModalOpen" (onClickClose)="toggleRankModalOpen()">
  <h3 class="text-2xl pb-5">The Rebellion Hierarchy</h3>
  <p class="pb-8">Earn MIST and advance in the rank of the Rebellion.</p>

  <div class="flex flex-col gap-y-3">
    <!--TODO: repeat ranks from rank.json -->
    <div class="flex flex-row items-center gap-8">
      <app-rank-item [rankName]="'Novice'" [size]="'large'"></app-rank-item>

      <div>
        <h3 class="text-2xl text-white">Novice</h3>
        <p class="text-gray-500">Unranked</p>
      </div>
    </div>

    <div class="flex flex-row items-center gap-8">
      <app-rank-item
        [rankName]="'Bolt Private'"
        [size]="'large'"
      ></app-rank-item>

      <div>
        <h3 class="text-2xl text-white">Bolt Private</h3>
        <p class="text-gray-500">LVL1</p>
      </div>
    </div>

    <div class="flex flex-row items-center gap-8">
      <app-rank-item
        [rankName]="'Copper Scout'"
        [size]="'large'"
      ></app-rank-item>

      <div>
        <h3 class="text-2xl text-white">Copper Scout</h3>
        <p class="text-gray-500">LVL2</p>
      </div>
    </div>

    <div class="flex flex-row items-center gap-8">
      <app-rank-item
        [rankName]="'Aether Technician'"
        [size]="'large'"
      ></app-rank-item>

      <div>
        <h3 class="text-2xl text-white">Aether Technician</h3>
        <p class="text-gray-500">LVL3</p>
      </div>
    </div>

    <div class="flex flex-row items-center gap-8">
      <app-rank-item
        [rankName]="'Mechanic Sergeant'"
        [size]="'large'"
      ></app-rank-item>

      <div>
        <h3 class="text-2xl text-white">Mechanic Sergeant</h3>
        <p class="text-gray-500">LVL4</p>
      </div>
    </div>

    <div class="flex flex-row items-center gap-8">
      <app-rank-item
        [rankName]="'Cloud Navigator'"
        [size]="'large'"
      ></app-rank-item>

      <div>
        <h3 class="text-2xl text-white">Cloud Navigator</h3>
        <p class="text-gray-500">LVL5</p>
      </div>
    </div>

    <div class="flex flex-row items-center gap-8">
      <app-rank-item
        [rankName]="'Gear Lieutenant'"
        [size]="'large'"
      ></app-rank-item>

      <div>
        <h3 class="text-2xl text-white">Gear Lieutenant</h3>
        <p class="text-gray-500">LVL6</p>
      </div>
    </div>

    <div class="flex flex-row items-center gap-8">
      <app-rank-item
        [rankName]="'Steam Captain'"
        [size]="'large'"
      ></app-rank-item>

      <div>
        <h3 class="text-2xl text-white">Steam Captain</h3>
        <p class="text-gray-500">LVL7</p>
      </div>
    </div>

    <div class="flex flex-row items-center gap-8">
      <app-rank-item
        [rankName]="'Sky Marshal'"
        [size]="'large'"
      ></app-rank-item>

      <div>
        <h3 class="text-2xl text-white">Sky Marshal</h3>
        <p class="text-gray-500">LVL8</p>
      </div>
    </div>

    <div class="flex flex-row items-center gap-8">
      <app-rank-item
        [rankName]="'High Engineer-Commander'"
        [size]="'large'"
      ></app-rank-item>

      <div>
        <h3 class="text-2xl text-white">High Engineer-Commander</h3>
        <p class="text-gray-500">LVL9</p>
      </div>
    </div>
  </div>
</app-modal>
