import {
  AfterViewInit,
  Component,
  ElementRef,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements AfterViewInit {
  public loginModalOpen: boolean = false;

  public inputStatus: 'incomplete' | 'valid' | 'loading' | 'invalid' =
    'incomplete';

  accessCodeParts: string[] = ['', '', '', '', '', ''];

  @ViewChildren('inputElement') inputElements:
    | QueryList<ElementRef>
    | undefined;

  constructor(private readonly api: ApiService) {}

  ngAfterViewInit(): void {
    this.focusFirstInput();
  }

  public toggleLoginModalOpen() {
    this.loginModalOpen = !this.loginModalOpen;
  }

  public manageFocus(event: KeyboardEvent, index: number): void {
    // TODO: Handle corner cases like arrow keys, delete, etc.
    setTimeout(() => {
      if (!this.inputElements) {
        return;
      }
      const target = event.target as HTMLInputElement;

      if (event.key === 'Backspace') {
        // Prevent default to delete manually
        event.preventDefault();

        // Delete the character if there is one and move focus if necessary
        if (target.value.length > 0) {
          target.value = '';
        } else if (index > 0) {
          const previousInput =
            this.inputElements.toArray()[index - 1].nativeElement;
          previousInput.focus();
        } else {
          // If we are at the first input, focus the first input
          this.focusFirstInput();
        }
      } else if (
        event.key.length === 1 &&
        !event.ctrlKey &&
        !event.altKey &&
        !event.metaKey
      ) {
        // For normal character inputs, we only focus next if the current field is already filled
        if (
          target.value.length >= 1 &&
          index < this.accessCodeParts.length - 1
        ) {
          const nextInput =
            this.inputElements.toArray()[index + 1].nativeElement;
          nextInput.focus();
        } else {
          this.focusLastInput();
        }
      }

      console.log(this.accessCodeParts.join(','));

      this.checkAccessCode();
    }, 0);
  }

  managePaste(event: ClipboardEvent) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData?.getData('text');
    if (pastedText && pastedText.length === 6) {
      this.accessCodeParts = pastedText.split('');
      this.checkAccessCode();
    } else {
      this.inputStatus = 'incomplete';
    }
  }

  private checkAccessCode(): void {
    if (this.accessCodeParts.join('').length === 6) {
      this.inputStatus = 'loading';
      this.api
        .checkReferralCode(this.accessCodeParts.join(''))
        .then((result) => {
          if (result.isValid) {
            this.inputStatus = 'valid';
            localStorage.setItem('referralCode', this.accessCodeParts.join(''));
          } else {
            this.inputStatus = 'invalid';
          }
        })
        .catch(() => (this.inputStatus = 'invalid'));
    } else {
      this.inputStatus = 'incomplete';
    }
  }

  private focusFirstInput() {
    if (this.inputElements && this.inputElements.length > 0) {
      const firstInput = this.inputElements.first.nativeElement;
      firstInput.focus();
    }
  }

  private focusLastInput() {
    if (this.inputElements && this.inputElements.length > 0) {
      const lastInput = this.inputElements.last.nativeElement;
      lastInput.focus();
    }
  }
}
