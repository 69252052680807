<div
  (click)="toggleQuestModalOpen()"
  class="overflow-hidden border border-gray-50 rounded-lg shadow w-full h-full overflow-y-auto mb-3 cursor-pointer card--container"
>
  <div class="flex flex-col md:flex-row md:justify-between px-4 py-5">
    <div class="flex flex-row items-start gap-3">
      <img
        [src]="'/assets/img/quests/' + questNumber + '.png'"
        width="48px"
        height="48px"
        class="rounded"
      />

      <div class="text-white">
        <h3 class="text-base font-semibold leading-6 text-white">
          {{ title }}
        </h3>
        <p class="text-gray-300">
          {{ description }}
        </p>
      </div>
    </div>
    <div class="flex flex-col pt-4 md:pt-0 md:items-end">
      <p class="text-white">{{ pointsAmount }} MIST</p>
      <p class="text-gray-400">{{ numberOfParticipants }} Participants</p>
    </div>
  </div>
</div>

<app-modal [open]="questModalOpen" (onClickClose)="toggleQuestModalOpen()">
  <div class="flex flex-col items-center text-center">
    <div class="pb-8">
      <h3 class="text-4xl pb-3">{{ title }}</h3>
      <p class="text-gray-300">{{ description }}</p>
    </div>

    <img
      [src]="'/assets/img/quests/' + questNumber + '.png'"
      width="196px"
      height="196px"
      class="rounded"
    />

    <div class="py-8">
      <p class="text-gray-300 uppercase">Rewards</p>
      <p class="text-white text-2xl">{{ pointsAmount }} MIST ☁️</p>
    </div>
  </div>

  <div class="py-8">
    <div class="flex flex-row justify-between pb-3">
      <p>3 Steps</p>
      <p class="text-gray-400">{{ numberOfParticipants }} Participants</p>
    </div>
    <!--TODO: progress -->
    <div class="progress--container w-full">
      <div
        class="progress--steps bg-primary-900"
        [ngStyle]="{ width: '33%' }"
      ></div>
    </div>
  </div>
  <div class="text-left">
    <!--TODO: real steps -->
    <app-quest-task-item
      [statusLocked]="false"
      [description]="
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.'
      "
    ></app-quest-task-item>
    <div class="flex flex-row justify-between pb-8">
      <button
        type="button"
        class="rounded-md bg-black text-gray-500 px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-500 hover:text-black hover:bg-primary-900"
      >
        Back
      </button>
      <button
        type="button"
        class="rounded-md bg-primary-900 text-black px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:text-black hover:bg-primary-500"
      >
        Continue
      </button>
    </div>

    <ng-container *ngFor="let _ of [].constructor(3)">
      <app-quest-task-item></app-quest-task-item
    ></ng-container>
  </div>
</app-modal>
