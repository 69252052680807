import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from './services/account/account.service';
import { AcurastNotification } from './components/elements/notification/notification.component';
import { NotificationService } from './services/notification/notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'acurast-points';

  public get notifications(): AcurastNotification[] {
    return this.notification.notifications;
  }

  constructor(
    private router: Router,
    private readonly notification: NotificationService,
    private readonly accounts: AccountService
  ) {}

  public async ngOnInit(): Promise<void> {
    await this.accounts.connectAll();
    // this.connected = this.accounts.hasAnySelectedAccount$;
  }

  isHomeRoute() {
    return this.router.url === '/';
  }

  public onClose(notification: Pick<AcurastNotification, 'id'>) {
    this.notification.close(notification);
  }
}
