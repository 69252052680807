<acurast-modal [open]="open" (onClickClose)="closeModal()" [darkMode]="true">
  <acurast-heading title="Connect Wallet"></acurast-heading>

  <p class="pb-6 max-w-prose text-white">
    Get started as a Processor or Consumer.
  </p>

  <div class="grid grid-col gap-3">
    <ng-container *ngIf="network$ | async as network">
      <ng-container *ngFor="let wallet of wallets">
        <div
          *ngIf="!wallet.networkType || network.type === wallet.networkType"
          class="flex items-center px-4 py-3 gap-4 bg-slate-100 rounded-md hover:ring-1 hover:ring-white cursor-pointer"
          [ngStyle]="{
            background:
              'linear-gradient(90deg, rgba(' +
              wallet.color[0] +
              ', ' +
              wallet.color[1] +
              ', ' +
              wallet.color[2] +
              ', 0.24) 0%, rgba(' +
              wallet.color[0] +
              ', ' +
              wallet.color[1] +
              ', ' +
              wallet.color[2] +
              ', 0.1) 100%)'
          }"
          [ngClass]="wallet.size"
          (click)="connectWallet(wallet.type)"
        >
          <img [src]="wallet.logo" class="w-6 h-6" />
          <p class="text-sm text-white font-bold">{{ wallet.name }}</p>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <!--TODO: only show after clicking on wallet e.g., Talisman while hiding all wallets -->

  <ng-container *ngIf="selectedAccountType === 'substrate' && showAccounts">
    <ng-container *ngIf="accounts$ | async as accounts">
      <acurast-account-list
        [accounts]="accounts"
        (onSelectedAccount)="selectAccount($event)"
      ></acurast-account-list>
    </ng-container>
  </ng-container>
</acurast-modal>
