import { ReplaySubject } from 'rxjs';
import { Account, SignatureType, AccountProvider, AccountSource, AccountType } from './account.provider';
import { InjectedExtension, InjectedAccountWithMeta, Unsubcall } from '@polkadot/extension-inject/types';
import { web3AccountsSubscribe, web3Enable, web3FromSource } from '@polkadot/extension-dapp';
import { Signer } from '@polkadot/api/types';
import { ApiPromise } from '@polkadot/api';

const SELECTED_ACCOUNT_KEY = 'acurast-selected-polkadot-account';

export class PolkadotExtensionAccount implements Account<InjectedAccountWithMeta> {
    public type: AccountType = 'substrate';
    public source: AccountSource = 'polkadotjs-extension';
    public get signatureType(): SignatureType {
        return this.internalAccount.type ?? 'sr25519';
    }
    private internalAccount: InjectedAccountWithMeta;

    constructor(account: InjectedAccountWithMeta) {
        this.internalAccount = account;
    }

    public address(): string {
        return this.internalAccount.address;
    }

    public name(): string | undefined {
        return this.internalAccount.meta.name;
    }

    public internal(): InjectedAccountWithMeta {
        return this.internalAccount;
    }
}

export class PolkadotExtensionAccountProvider implements AccountProvider<InjectedAccountWithMeta> {
    public type: AccountType = 'substrate';
    public selectedAccount$: ReplaySubject<Account<InjectedAccountWithMeta> | null> = new ReplaySubject(1);

    private extensions: InjectedExtension[] = [];
    private connectPromise?: Promise<InjectedExtension[]>;

    public accounts$: ReplaySubject<Account<InjectedAccountWithMeta>[] | null> = new ReplaySubject(1);

    private unsubAccounts?: Unsubcall;

    constructor() {
        this.selectedAccount$.next(null);
    }

    public async connect(): Promise<void> {
        if (this.extensions.length !== 0) {
            return;
        }

        if (this.connectPromise !== undefined) {
            await this.connectPromise;
            return;
        }

        const storedAccount = localStorage.getItem(SELECTED_ACCOUNT_KEY);
        let selected: InjectedAccountWithMeta | undefined = undefined;
        if (storedAccount) {
            try {
                selected = JSON.parse(storedAccount);
            } catch (error) {
                console.error(error);
            }
        }
        if (selected !== undefined) {
            this.selectedAccount$.next(new PolkadotExtensionAccount(selected));
        } else {
            this.selectedAccount$.next(null);
        }
        this.unsubscribeAccounts();
        this.connectPromise = this.tryConnect().then(async (extensions) => {
            this.unsubAccounts = await web3AccountsSubscribe((accounts) => {
                this.accounts$.next(accounts.map((v) => new PolkadotExtensionAccount(v)));
                if (selected !== undefined) {
                    const selectedAccount = selected;
                    const found = accounts.find((item) => item.address == selectedAccount.address);
                    if (!found) {
                        this.selectedAccount$.next(null);
                    }
                }
            });
            return extensions;
        });
    }

    public hasStoredAccount(): boolean {
        return localStorage.getItem(SELECTED_ACCOUNT_KEY) !== null;
    }

    private async tryConnect(maxTries: number = 5, delay: number = 100): Promise<InjectedExtension[]> {
        return new Promise(async (resolve) => {
            const extensions = await web3Enable('Acurast Console');
            if (extensions.length === 0 && maxTries > 0) {
                setTimeout(async () => {
                    resolve(await this.tryConnect(maxTries - 1, delay));
                }, delay);
            } else {
                resolve(extensions);
            }
        });
    }

    public async disconnect(): Promise<void> {
        this.connectPromise = undefined;
        this.extensions = [];
        this.unsubscribeAccounts();
        this.setSelectedAccount(null);
    }

    public async setSelectedAccount(account: Account<InjectedAccountWithMeta> | null) {
        if (account) {
            localStorage.setItem(SELECTED_ACCOUNT_KEY, JSON.stringify(account.internal()));
        } else {
            localStorage.removeItem(SELECTED_ACCOUNT_KEY);
        }
        this.selectedAccount$.next(account);
    }

    public async signMessage(
        message: string,
        account: Account<InjectedAccountWithMeta>,
        _api: ApiPromise,
    ): Promise<string | undefined> {
        let result: string | undefined = undefined;
        const injector = await web3FromSource(account.internal().meta.source);
        const signRaw = injector?.signer?.signRaw;
        if (!!signRaw) {
            const { signature } = await signRaw({
                address: account.address(),
                data: message,
                type: 'bytes',
            });
            result = signature;
        }
        return result;
    }

    private unsubscribeAccounts() {
        if (this.unsubAccounts) {
            this.unsubAccounts();
            this.unsubAccounts = undefined;
        }
    }

    public async getSigner(): Promise<Signer | undefined> {
        return undefined;
    }
}
