import { Component, Input, OnInit } from '@angular/core';
import '@polkadot/api-augment';

@Component({
  selector: 'acurast-heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.scss'],
})
export class HeadingComponent implements OnInit {
  @Input() title: string = 'Title';
  @Input() darkMode: boolean = false;

  constructor() {}

  ngOnInit() {}
}
