import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'acurast-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnInit {
    @Input() variant: string | undefined;
    @Input() color: string = 'slate';
    @Input() background: boolean = false;
    backgroundClass = '';

    constructor() {}

    ngOnInit(): void {}

    ngOnChanges(): void {
        if (this.background) {
            this.backgroundClass =
                'inline-flex rounded-lg p-3 bg-' + this.color + '-50 text-' + this.color + '-700 ring-4 ring-white';
        }
    }
}
