<div
  *ngIf="open"
  class="relative z-50"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true"
>
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

  <div class="fixed inset-0 z-10 overflow-y-auto">
    <div
      id="acurast-modal-outside"
      class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
      (click)="onClose($event)"
    >
      <div
        class="relative transform overflow-hidden rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6"
        [ngClass]="darkMode ? 'bg-black' : 'bg-white'"
      >
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
