<button
  class="relative inline-flex items-center rounded-md border border-transparent bg-primary-900 px-4 py-2 text-sm font-medium text-black shadow-sm hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:bg-gray-400"
  [ngClass]="class"
  (click)="onClick()"
  [disabled]="showSpinner || disabled"
>
  <ng-container *ngIf="showSpinner">
    <acurast-spinner></acurast-spinner>
  </ng-container>
  {{ label }} <ng-content></ng-content>
</button>
