import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AccountService,
  walletUiInfo,
} from 'src/app/services/account/account.service';
import { InjectedAccountWithMeta } from '@polkadot/extension-inject/types';
import { Observable, ReplaySubject, map } from 'rxjs';
import {
  Account,
  AccountSource,
  AccountType,
} from 'src/app/services/account/account.provider';
import {
  Network,
  NetworkService,
} from 'src/app/services/network/network.service';

@Component({
  selector: 'acurast-account-selection-modal',
  templateUrl: './account-selection-modal.component.html',
  styleUrls: ['./account-selection-modal.component.scss'],
})
export class AccountSelectionModalComponent implements OnInit {
  @Input() open: boolean = false;
  @Input() allowedAccountTypes: AccountType[] = ['substrate', 'tezos'];
  @Output() onSelectedAccount = new EventEmitter<
    Account<InjectedAccountWithMeta>
  >();
  @Output() onClose = new EventEmitter<void>();

  public showAccounts: boolean = false;

  public wallets: {
    type: AccountSource | 'walletconnect' | 'subwallet';
    name: string;
    logo: string;
    color: [number, number, number];
    url: string;
    size: 'col-span-1' | 'col-span-2';
    networkType?: 'dev';
  }[] = Object.values(walletUiInfo);

  public get selectedAccountType(): AccountType {
    return this.allowedAccountTypes[this.selectedAccountTypeIndex];
  }
  public accountTypeName: Record<AccountType, string> = {
    substrate: 'Polkadot',
    tezos: 'Tezos',
  };

  private selectedAccountTypeIndex: number = 0;

  public get accounts$(): ReplaySubject<
    Account<any /* TODO: Types */>[] | null
  > {
    return this.accounts.provider('substrate').accounts$;
  }

  public get network$(): Observable<Network> {
    return this.network.currentNetwork$;
  }

  constructor(
    private readonly accounts: AccountService,
    private readonly network: NetworkService
  ) {}

  public ngOnInit() {
    if (this.allowedAccountTypes.length === 0) {
      this.allowedAccountTypes = ['substrate', 'tezos'];
    }
  }

  public closeModal() {
    this.onClose.emit();
  }

  public selectAccount(account: Account<InjectedAccountWithMeta>) {
    this.onSelectedAccount.emit(account);
  }

  public setAccountType(index: number) {
    this.selectedAccountTypeIndex = index;
  }

  public connectPasskeyWallet() {
    this.accounts.provider('substrate').requestAccount('passkey');
    this.closeModal();
  }

  public connectSuperheroWallet() {
    this.accounts.provider('substrate').requestAccount('superhero');
    this.closeModal();
  }

  public connectMetamaskWallet() {
    this.accounts.provider('substrate').requestAccount('metamask');
    this.closeModal();
  }

  public connectTezosWallet() {
    this.accounts.provider('tezos').requestAccount();
    this.closeModal();
  }

  public connectWallet(wallet: AccountSource | 'walletconnect' | 'subwallet') {
    if (wallet === 'polkadotjs-extension') {
      this.accounts.provider('substrate').connect();
      this.showAccounts = true;
    } else if (wallet === 'walletconnect') {
      //
    } else if (wallet === 'beacon') {
      this.connectTezosWallet();
    } else if (wallet === 'metamask') {
      this.connectMetamaskWallet();
    } else if (wallet === 'passkey') {
      this.connectPasskeyWallet();
    } else if (wallet === 'superhero') {
      this.connectSuperheroWallet();
    } else if (wallet === 'subwallet') {
      //
    } else {
    }
  }
}
