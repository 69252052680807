import { Pipe, PipeTransform } from '@angular/core';
import { Account } from 'src/app/services/account/account.provider';

export type AccountNamePipeOptions = {
  onlyName?: boolean;
  onlyAddress?: boolean;
  shortAddress?: boolean;
};

@Pipe({
  name: 'accountName',
})
export class AccountNamePipe implements PipeTransform {
  public transform(
    value: Account<any>,
    options?: AccountNamePipeOptions
  ): string {
    if (options?.onlyName) {
      return value.name() ?? this.address(value, options?.shortAddress);
    }
    if (options?.onlyAddress) {
      return this.address(value, options?.shortAddress);
    }
    const shortAddress = this.address(value, options?.shortAddress);
    if (value.name()) {
      return `${value.name()} (${shortAddress})`;
    }
    return shortAddress;
  }

  private address(value: Account<any>, short?: boolean): string {
    if (short === undefined || short) {
      return value.address().slice(0, 7) + '...' + value.address().slice(-7);
    }
    return value.address();
  }
}
