import { Injectable } from '@angular/core';
import {
  Account,
  AccountSource,
  AccountType,
  AccountTypeProviderMapping,
  GetAccount,
  GetAccountProvider,
} from './account.provider';
import { BeaconAccountProvider } from './beacon.account.provider';
import { Observable, ReplaySubject, combineLatest, map } from 'rxjs';
import { NetworkService } from '../network/network.service';
import { TabSyncService } from '../tab-sync/tab-sync.service';
import { SubstrateProxyAccountProvider } from './substrate.account.provider';

const SELECTED_ACCOUNT_KEY = 'acurast-selected-polkadot-account';

// TODO: Temporary definition for account info. Need providers for walletconnect and subwallet
export const walletUiInfo: {
  [key in AccountSource | 'walletconnect' | 'subwallet']: {
    type: AccountSource | 'walletconnect' | 'subwallet';
    name: string;
    logo: string;
    color: [number, number, number];
    url: string;
    size: 'col-span-1' | 'col-span-2';
    networkType?: 'dev';
  };
} = {
  ['polkadotjs-extension']: {
    type: 'polkadotjs-extension',
    name: 'Talisman',
    logo: '/assets/svg/wallets/talisman-logo.png',
    color: [212, 255, 93],
    url: '',
    size: 'col-span-2',
  },
  ['beacon']: {
    type: 'beacon',
    name: 'Beacon: Tezos Wallets',
    logo: '/assets/svg/wallets/beacon-logo.svg',
    color: [58, 128, 255],
    url: '',
    size: 'col-span-1',
  },
  ['superhero']: {
    type: 'superhero',
    name: 'SuperHero Wallet',
    logo: '/assets/svg/wallets/superhero-logo.svg',
    color: [22, 97, 254],
    url: '',
    size: 'col-span-1',
  },
  ['passkey']: {
    type: 'passkey',
    name: 'Passkey',
    logo: '',
    color: [0, 0, 0],
    url: '',
    size: 'col-span-1',
    networkType: 'dev',
  },
  ['walletconnect']: {
    type: 'walletconnect',
    name: 'WalletConnect',
    logo: '/assets/svg/wallets/walletconnect-logo.svg',
    color: [87, 111, 255],
    url: '',
    size: 'col-span-1',
    networkType: 'dev',
  },
  ['subwallet']: {
    type: 'subwallet',
    name: 'SubWallet',
    logo: '/assets/svg/wallets/subwallet-logo.svg',
    color: [16, 93, 247],
    url: '',
    size: 'col-span-1',
    networkType: 'dev',
  },
  ['metamask']: {
    type: 'metamask',
    name: 'MetaMask',
    logo: '/assets/svg/wallets/metamask-logo.svg',
    color: [255, 138, 0],
    url: '',
    size: 'col-span-1',
    networkType: 'dev',
  },
};

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private providers: AccountTypeProviderMapping = {
    substrate: new SubstrateProxyAccountProvider(this.network, this.tabSync),
    tezos: new BeaconAccountProvider(this.network),
  };

  public anySelectedAccounts$: Observable<Account<any>[]>;
  public hasAnySelectedAccount$: Observable<boolean>;

  constructor(
    private readonly network: NetworkService,
    private readonly tabSync: TabSyncService
  ) {
    this.anySelectedAccounts$ = combineLatest([
      this.provider('substrate').selectedAccount$,
      this.provider('tezos').selectedAccount$,
    ]).pipe(
      map(([polkadot, beacon]) => {
        const result: Account<any>[] = [];
        if (polkadot) {
          result.push(polkadot);
        }
        if (beacon) {
          result.push(beacon);
        }
        return result;
      })
    );
    this.hasAnySelectedAccount$ = combineLatest([
      this.provider('substrate').selectedAccount$,
      this.provider('tezos').selectedAccount$,
    ]).pipe(
      map(([polkadot, beacon]) => {
        return polkadot !== null || beacon !== null;
      })
    );
  }

  public provider<T extends AccountType>(type: T): GetAccountProvider<T> {
    return this.providers[type] as GetAccountProvider<T>;
  }

  public async connect(accountType: AccountType): Promise<void> {
    await this.providers[accountType].connect();
  }

  public async connectAll(): Promise<void> {
    const connects = Object.values(this.providers).map((provider) => {
      if (
        provider.type === 'substrate' &&
        localStorage.getItem(SELECTED_ACCOUNT_KEY) !== null
      ) {
        // TODO: Use "hasStoredAccount" instead of "localStorage.getItem(SELECTED_ACCOUNT_KEY) !== null"?
        provider.connect();
      }
    });
    await Promise.all(connects);
  }

  public async disconnect(accountType: AccountType): Promise<void> {
    await this.providers[accountType].disconnect();
  }

  public async disconnectAll(): Promise<void> {
    const connects = Object.values(this.providers).map((provider) =>
      provider.disconnect()
    );
    await Promise.all(connects);
  }

  public selectedAccount<T extends AccountType>(
    type: T
  ): Observable<GetAccount<T> | null> {
    return this.getSelectedAccount(type);
  }

  private getSelectedAccount<T extends AccountType>(
    type: T
  ): ReplaySubject<GetAccount<T> | null> {
    return this.provider(type)
      .selectedAccount$ as ReplaySubject<GetAccount<T> | null>;
  }
}
