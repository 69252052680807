import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, combineLatest, map } from 'rxjs';
import {
  Account,
  AccountType,
} from 'src/app/services/account/account.provider';
import { AccountService } from 'src/app/services/account/account.service';
import { NetworkService } from 'src/app/services/network/network.service';
import { InjectedAccountWithMeta } from '@polkadot/extension-inject/types';
import { ApiService } from 'src/app/services/api/api.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  public connectModalOpen: boolean = false;
  public allowedAccountTypes: AccountType[] = ['substrate'];
  public get hasConnectedAccount$(): Observable<boolean> {
    return this.accounts.hasAnySelectedAccount$;
  }
  public get accounts$(): Observable<Account<any>[]> {
    return this.accounts.anySelectedAccounts$;
  }
  public hasSubstrateAccount$: Observable<boolean>;
  public hasTezosAccount$: Observable<boolean>;
  public explorerUrl$: Observable<string>;

  public loginModalOpen: boolean = false;

  public isLoggedIn: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly accounts: AccountService,
    private readonly network: NetworkService,
    private readonly api: ApiService
  ) {
    this.router.events.subscribe((event) => {
      // if (event instanceof NavigationEnd) {
      //   let path = event.url;
      //   if (path.startsWith('/') && path.length > 1) {
      //     path = path.split('/')[1];
      //   }
      //   if (isMenuItem(path)) {
      //     this.selectedMenuItem = path;
      //   } else if (path === 'job-detail') {
      //     this.selectedMenuItem = 'jobs';
      //   } else {
      //     this.selectedMenuItem = 'dashboard';
      //   }
      // }
    });
    this.hasSubstrateAccount$ = this.accounts
      .selectedAccount('substrate')
      .pipe(map((account) => account !== null));
    this.hasTezosAccount$ = this.accounts
      .selectedAccount('tezos')
      .pipe(map((account) => account !== null));
    this.explorerUrl$ = this.network.currentNetwork$.pipe(
      map(
        (network) =>
          `https://polkadot.js.org/apps/?rpc=${network.acurast.wsUrl}`
      )
    );

    this.accounts.anySelectedAccounts$.subscribe((accounts) => {
      console.log('ACCOUNTS', accounts);
    });

    combineLatest([this.hasSubstrateAccount$, this.hasTezosAccount$]).subscribe(
      ([hasSubstrateAccount, hasTezosAccount]) => {
        if (!hasSubstrateAccount && !hasTezosAccount) {
          this.connectModalOpen = true;
          this.isLoggedIn = false;
        } else {
          this.isLoggedIn = true;
        }
      }
    );
  }

  public async connectAccount(type: AccountType) {
    switch (type) {
      case 'substrate':
        this.connectModalOpen = true;
        break;
      case 'tezos':
        await this.accounts.provider('tezos').requestAccount();
    }
  }

  public onCloseModal() {
    this.connectModalOpen = false;
  }

  public async selectAccount(account: Account<InjectedAccountWithMeta>) {
    console.log('SELECTING ACCOUNT', account);
    this.accounts.provider('substrate').setSelectedAccount(account);
    localStorage.setItem('selectedAccount', account.address());

    const user = await this.api.getUserByAddress(account.address());

    if (!user) {
      console.log(
        'NO USER, REGISTRING WITH REFERRAL CODE',
        localStorage.getItem('referralCode')
      );
      await this.api.registerUser({
        referralCode: localStorage.getItem('referralCode') || '',
        manager: {
          address: account.address(),
          challenge: '0x12345',
          signature: '0x12345',
        },
      });
      await this.api.getMyUser(account.address());
    } else {
      console.log('USER EXISTS', user);
      await this.api.user$.next(user);
    }
    this.connectModalOpen = false;
  }

  public async disconnect() {
    this.accounts.disconnectAll();
    this.api.user$.next(undefined);
  }

  ngOnInit(): void {}

  public toggleLoginModalOpen() {
    this.loginModalOpen = !this.loginModalOpen;
  }
}
