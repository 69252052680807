import { Component, OnInit } from '@angular/core';
import { LeaderboardItemComponent } from 'src/app/components/leaderboard-item/leaderboard-item.component';
import { ApiService } from 'src/app/services/api/api.service';

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.scss'],
})
export class LeaderboardComponent implements OnInit {
  public leaderboard$ = this.api.getLeaderboard();

  constructor(private readonly api: ApiService) {}

  ngOnInit(): void {}
}
