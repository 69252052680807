import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-quest-card',
  templateUrl: './quest-card.component.html',
  styleUrls: ['./quest-card.component.scss'],
})
export class QuestCardComponent implements OnInit {
  @Input() questNumber: string = '1';
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() pointsAmount: string = '';
  @Input() numberOfParticipants: string = '';
  public questModalOpen: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  public toggleQuestModalOpen() {
    this.questModalOpen = !this.questModalOpen;
  }
}
