import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

export interface UserEntry {
  id: number;
  balance: number;
  balanceCalculated: number;
  managers: string[];
  nofProcessors: number;
  nofReferred: number;
  referralCode: string;
  rewards: any[]; // TODO: Type
  balanceWithReferrals: number;
  referralInfo: any | undefined; // TODO: Type
  leaderboardPosition: number;
}

export interface LeaderboardItem {
  id: number;
  position: number;
  name: string;
  points: number;
  rank: string;
  processors: number;
}

const API_URL = 'https://acurast-points-backend.dev.gke.papers.tech';
// const API_URL = 'http://localhost:9001';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  user$: BehaviorSubject<UserEntry | undefined> = new BehaviorSubject<
    UserEntry | undefined
  >(undefined);

  constructor(private readonly http: HttpClient) {}

  async checkReferralCode(code: string): Promise<{ isValid: boolean }> {
    return firstValueFrom(
      this.http.post<{ isValid: boolean }>(`${API_URL}/code/validate`, {
        referralCode: code,
      })
    );
  }

  async getProcessors(userId: string) {
    return firstValueFrom(
      this.http.get(`${API_URL}/processors?userId=${userId}`)
    );
  }

  async getRewards(userId: string) {
    return firstValueFrom(this.http.get(`${API_URL}/rewards?userId=${userId}`));
  }

  async registerUser(payload: {
    referralCode: string;
    manager: {
      address: string;
      challenge: string;
      signature: string;
    };
  }) {
    this.http.post(`${API_URL}/users`, payload).subscribe();
  }

  async getSeasonInfo() {
    return [
      {
        id: 1,
        name: 'Season 1',
        startDate: new Date('2024-01-01'),
        endDate: new Date('2024-03-31'),
      },
      {
        id: 2,
        name: 'Season 2',
        startDate: new Date('2024-04-01'),
        endDate: new Date('2024-06-31'),
      },
    ];
  }

  async getQuestGroupList() {
    return [
      {
        id: 1,
        name: 'Introduction',
        seasonId: 1,
      },
      {
        id: 2,
        name: 'Get Started',
        seasonId: 1,
      },
      {
        id: 3,
        name: 'Processors',
        seasonId: 1,
      },
      {
        id: 4,
        name: 'Consumer',
        seasonId: 1,
      },
    ];
  }

  async getQuestList() {
    return [
      {
        id: 1,
        title: 'Welcome to the Cloud Rebellion',
        shortDescription: 'Learn about the Cloud Rebellion and Acurast.',
        image: '/assets/img/quests/1.png',
        description: 'Description 1',
        reward: 10,
        participants: 4,
        groupId: 1,
        seasonId: 1,
      },
      {
        id: 2,
        title: 'Connect with Acurast',
        shortDescription:
          'Keep up with the latest updates and follow Acurast on social channels.',
        image: '/assets/img/quests/2.png',
        description: 'Description 2',
        reward: 20,
        participants: 4,
        groupId: 1,
        seasonId: 1,
      },
      {
        id: 3,
        title: 'Become part of the Rebellion: Processor',
        shortDescription: 'Onboard a phone and become an Acurast Processor.',
        image: '/assets/img/quests/3.png',
        description: 'Description 2',
        reward: 20,
        participants: 4,
        groupId: 2,
        seasonId: 1,
      },
      {
        id: 4,
        title: 'Become part of the Rebellion: Consumer',
        shortDescription:
          'Deploy your first application to the decentralized cloud.',
        image: '/assets/img/quests/4.png',
        description: 'Description 2',
        reward: 20,
        participants: 4,
        groupId: 2,
        seasonId: 1,
      },
      {
        id: 5,
        title: 'Recruit for the Rebellion',
        shortDescription: 'Use your referal link to onboard other Processors.',
        image: '/assets/img/quests/5.png',
        description: 'Description 2',
        reward: 20,
        participants: 4,
        groupId: 3,
        seasonId: 1,
      },
      {
        id: 6,
        title: 'Deploy for the Rebellion',
        shortDescription:
          'Deploy a interval-based application to the decentralized cloud.',
        image: '/assets/img/quests/6.png',
        description: 'Description 2',
        reward: 20,
        participants: 4,
        groupId: 4,
        seasonId: 1,
      },
    ];
  }

  async getQuestDetails(id: number) {
    return this.getQuestList().then((quests) =>
      quests.find((q) => q.id === id)
    );
  }

  async hasProcessor(): Promise<boolean> {
    return false;
  }

  async getUser(address: string) {
    const users = await this.getUsers();
    return users.find((u) => u.managers.includes(address));
  }

  async getUserByAddress(address: string) {
    const users = await this.getUsers();
    return users.find((u) => u.managers.includes(address));
  }

  async getUsers(): Promise<any[]> {
    return firstValueFrom(this.http.get<any[]>(`${API_URL}/users`));
  }

  async getMyUser(address: string) {
    const user = await this.getUserByAddress(address);
    this.user$.next(user);
    return user;
  }

  async getLeaderboard(): Promise<UserEntry[]> {
    return firstValueFrom(this.http.get<UserEntry[]>(`${API_URL}/leaderboard`));
  }
}
